import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@rio-cloud/rio-uikit";
import { useAppSelector } from "../../../configuration/setup/hooks";
import { useUpdateSingleChartMutation } from "../../../services/customReportApi";
import { getAppData } from "../../../stores/app/appSlice";
import { ChartJSON } from "../types/chart";

const chartFormSchema = z.object({
	chartName: z
		.string()
		.trim()
		.min(1, { message: "O nome do gráfico é obrigatório" })
		.max(150, { message: "O nome do gráfico deve ter no máximo 150 caracteres" }),
	subject: z.enum(["VEHICLE", "DRIVER"], {
		required_error: "O assunto do gráfico é obrigatório",
	}),
	mainData: z.string().min(1, { message: "O conteúdo principal é obrigatório" }),
	complementaryData: z.string().optional(),
	timeRange: z.string().nullable().optional(),
});

type ChartFormInputs = z.infer<typeof chartFormSchema>;

const MAIN_DATA_OPTIONS = [
	{ id: "DISTANCE", label: "Distância" },
	{ id: "ENGINE_HOURS", label: "Motor ligado" },
	{ id: "MAX_SPEED", label: "Velocidade máxima" },
	{ id: "FUEL", label: "Combustível" },
	{ id: "AVERAGE_FUEL", label: "Média de combustível" },
	{ id: "TRIP", label: "Viagens" },
	{ id: "HISTOGRAM", label: "Histograma" },
	{ id: "INFRACTIONS", label: "Infrações" },
];

const COMPLEMENTARY_HISTOGRAM_OPTIONS = [
	{ id: "SPEED", label: "Velocidade" },
	{ id: "RPM", label: "RPM" },
	{ id: "ACCELERATOR", label: "Acelerador" },
	{ id: "ENGINE_BRAKE", label: "Freio motor" },
	{ id: "CRUISE_CONTROL", label: "Controle de cruzeiro" },
	{ id: "TIME_WITH_PTO", label: "Tempo em PTO" },
	{ id: "GEAR", label: "Marcha" },
];

const COMPLEMENTARY_INFRACTION_OPTIONS = [
	{ id: "ALL_INFRACTIONS", label: "Todas infrações" },
	{ id: "IT_KICKDOWN", label: "Arrancada" },
	{ id: "IT_RPM", label: "RPM" },
	{ id: "IT_SPEED", label: "Velocidade" },
	{ id: "IT_SPEED_RAIN", label: "Velocidade na chuva" },
	{ id: "IT_IDLING", label: "Marcha lenta" },
	{ id: "IT_CLUTCH", label: "Embreagem" },
	{ id: "IT_ACCELERATION", label: "Aceleração" },
];

interface EditChartFormProps {
	chart: ChartJSON;
}

export const EditChartForm: React.FC<EditChartFormProps> = ({ chart }) => {
	const { dateTime } = useAppSelector(getAppData);
	const [editChart] = useUpdateSingleChartMutation();

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors, isDirty, isValid },
	} = useForm<ChartFormInputs>({
		mode: "all",
		resolver: zodResolver(chartFormSchema),
		defaultValues: {
			chartName: chart.chartName,
			subject: chart.subject as "VEHICLE" | "DRIVER",
			mainData: chart.mainData,
			complementaryData: chart.complementaryData || "",
			timeRange: chart.timeRange || null,
		},
	});

	const selectedMainData = watch("mainData");
	const isHistogram = selectedMainData === "HISTOGRAM";
	const isInfractions = selectedMainData === "INFRACTIONS";

	const complementaryDataOptions = useMemo(() => {
		if (isHistogram) return COMPLEMENTARY_HISTOGRAM_OPTIONS;
		if (isInfractions) return COMPLEMENTARY_INFRACTION_OPTIONS;
		return [];
	}, [isHistogram, isInfractions]);

	useEffect(() => {
		if (isHistogram) {
			setValue("timeRange", null);
		}
	}, [isHistogram, setValue]);

	const onSubmit = async (formData: ChartFormInputs) => {
		try {
			const chartType = isHistogram ? "BAR" : "LINE";

			const chartToBeUpdated = {
				...chart,
				...formData,
				chartType,
				columnSize: 5,
				complementaryData:
					formData.complementaryData === "ALL_INFRACTIONS" || !formData.complementaryData ? null : formData.complementaryData,
				viewOrder: 99,
				startAt: dateTime.start,
				endAt: dateTime.end,
				timeRange: chart.timeRange ?? null,
			};

			await editChart([chartToBeUpdated]).unwrap();
		} catch (error) {
			console.error("Failed to update chart:", error);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="space-y-10">
			<div className="form-group">
				<label htmlFor="chartName" className="block mb-2">
					Nome do gráfico
				</label>
				<input
					{...register("chartName")}
					id="chartName"
					className="form-control w-full"
					placeholder="Digite o nome do gráfico"
					maxLength={150}
				/>
				{errors.chartName && <span className="text-red-500 text-sm">{errors.chartName.message}</span>}
			</div>
			<div className="form-group">
				<label className="block mb-2">Assunto do gráfico</label>
				<div className="display-flex gap-10 align-items-center">
					{(["VEHICLE", "DRIVER"] as const).map((subject) => (
						<label key={subject} className="radio">
							<input type="radio" {...register("subject")} value={subject} className="radioGroup" />
							<span className="radio-text">{subject === "VEHICLE" ? "Veículo" : "Motorista"}</span>
						</label>
					))}
				</div>
				{errors.subject && <span className="text-red-500 text-sm">{errors.subject.message}</span>}
			</div>
			<div className="form-group">
				<label htmlFor="mainData" className="block mb-2">
					Conteúdo principal
				</label>
				<select {...register("mainData")} id="mainData" className="form-control w-full">
					<option value="">Selecione</option>
					{MAIN_DATA_OPTIONS.map((option) => (
						<option key={option.id} value={option.id}>
							{option.label}
						</option>
					))}
				</select>
				{errors.mainData && <span className="text-red-500 text-sm">{errors.mainData.message}</span>}
			</div>
			{(isHistogram || isInfractions) && complementaryDataOptions.length > 0 && (
				<div className="form-group">
					<label htmlFor="complementaryData" className="block mb-2">
						Conteúdo complementar
					</label>
					<select {...register("complementaryData")} id="complementaryData" className="form-control w-full">
						<option value="">Selecione</option>
						{complementaryDataOptions.map((option) => (
							<option key={option.id} value={option.id}>
								{option.label}
							</option>
						))}
					</select>
					{errors.complementaryData && <span className="text-red-500 text-sm">{errors.complementaryData.message}</span>}
				</div>
			)}
			<div className="margin-top-10">
				<Button type="submit" disabled={!isValid || !isDirty} block bsStyle={Button.PRIMARY}>
					Salvar
				</Button>
			</div>
		</form>
	);
};

export default EditChartForm;
