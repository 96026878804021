import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "../configuration/tokenHandling/prepareHeaders";
import { ChartJSON } from "../pages/custom-report/types/chart";

const formatDateForAPI = (date: string): string => {
	try {
		// Parse the date and ensure it has a timezone offset
		const parsedDate = new Date(date);

		// If the date is invalid, return the original string
		if (isNaN(parsedDate.getTime())) {
			return date;
		}

		// Convert to ISO string with timezone offset
		const isoString = parsedDate.toISOString();

		// Remove milliseconds and add timezone offset if not present
		return isoString.split(".")[0] + (date.includes("-") ? date.match(/-\d{2}:\d{2}$/)?.[0] || "-03:00" : "-03:00");
	} catch (error) {
		// Fallback to original date if parsing fails
		return date;
	}
};

export const customReportApi = createApi({
	reducerPath: "customReportApi",
	baseQuery: fetchBaseQuery({ prepareHeaders }),
	endpoints: (builder) => ({
		getCharts: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "POST",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/custom-report",
			}),
		}),
		getListChartsIds: builder.query<any, any>({
			query: (requestPayload) => ({
				method: "POST",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/custom-report/informations",
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		createChart: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "POST",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/custom-report/config",
			}),
		}),
		getSingleChartById: builder.mutation<any, { customId: string; requestPayload: any }>({
			query: ({ customId, requestPayload }) => ({
				url: `https://api.latam-perform.rio.cloud/rio-perform/custom-report/${customId}`,
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data[0];
				},
			}),
		}),
		updateSingleChart: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "PUT",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/custom-report/config",
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data[0];
				},
			}),
		}),
		deleteSingleChart: builder.mutation<ChartJSON, any>({
			query: (requestPayload) => ({
				method: "DELETE",
				body: {
					...requestPayload,
					startAt: formatDateForAPI(requestPayload.startDate),
					endAt: formatDateForAPI(requestPayload.endDate),
				},
				url: "https://api.latam-perform.rio.cloud/rio-perform/custom-report/config",
			}),
		}),
	}),
});

export const {
	useLazyGetListChartsIdsQuery,
	useGetChartsMutation,
	useUpdateSingleChartMutation,
	useCreateChartMutation,
	useGetSingleChartByIdMutation,
	useDeleteSingleChartMutation,
} = customReportApi;
