import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@rio-cloud/rio-uikit";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useAppSelector } from "../../../configuration/setup/hooks";
import { getAppData } from "../../../stores/app/appSlice";
import { getCustomReportPageData } from "../../../stores/customReport/customReportPageSlice";
import { useCreateChartMutation } from "../../../services/customReportApi";

const chartFormSchema = z.object({
	chartName: z
		.string()
		.trim()
		.min(1, { message: "O nome do gráfico é obrigatório" })
		.max(150, { message: "O nome do gráfico deve ter no máximo 150 caracteres" }),
});

type ChartFormInputs = z.infer<typeof chartFormSchema>;

const COMPLEMENTARY_HISTOGRAM_OPTIONS = [
	{ id: "SPEED", label: "Velocidade" },
	{ id: "RPM", label: "RPM" },
	{ id: "ACCELERATOR", label: "Acelerador" },
	{ id: "ENGINE_BRAKE", label: "Freio motor" },
	{ id: "CRUISE_CONTROL", label: "Controle de cruzeiro" },
	{ id: "TIME_WITH_PTO", label: "Tempo em PTO" },
	{ id: "GEAR", label: "Marcha" },
];

const COMPLEMENTARY_INFRACTION_OPTIONS = [
	{ id: "ALL_INFRACTIONS", label: "Todas infrações" },
	{ id: "IT_KICKDOWN", label: "Arrancada" },
	{ id: "IT_RPM", label: "RPM" },
	{ id: "IT_SPEED", label: "Velocidade" },
	{ id: "IT_SPEED_RAIN", label: "Velocidade na chuva" },
	{ id: "IT_IDLING", label: "Marcha lenta" },
	{ id: "IT_CLUTCH", label: "Embreagem" },
	{ id: "IT_ACCELERATION", label: "Aceleração" },
];

export const DuplicateChartForm = ({ setDuplicateConfirm }: { setDuplicateConfirm: (val: boolean) => void }) => {
	const { dateTime } = useAppSelector(getAppData);
	const [createChart] = useCreateChartMutation();
	const { activeChart } = useAppSelector(getCustomReportPageData);
	if (!activeChart) return;

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm<ChartFormInputs>({
		mode: "all",
		resolver: zodResolver(chartFormSchema),
		defaultValues: {
			chartName: "",
		},
	});

	const onSubmit = async (formData: ChartFormInputs) => {
		try {
			const chartToBeCreated = {
				...activeChart,
				startAt: dateTime.start,
				endAt: dateTime.end,
				...formData,
			};

			await createChart(chartToBeCreated).unwrap();
			setDuplicateConfirm(false);
		} catch (error) {
			console.error("Failed to update chart:", error);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="space-y-10">
			<div className="form-group">
				<label htmlFor="chartName" className="block mb-2">
					Nome do gráfico
				</label>
				<input
					{...register("chartName")}
					id="chartName"
					className="form-control w-full"
					placeholder="Digite o nome do gráfico"
					maxLength={150}
				/>
				{errors.chartName && <span className="text-red-500 text-sm">{errors.chartName.message}</span>}
			</div>
			<div className="margin-top-10">
				<Button type="submit" disabled={!isValid || !isDirty} block bsStyle={Button.PRIMARY}>
					Salvar
				</Button>
			</div>
		</form>
	);
};

export default DuplicateChartForm;
