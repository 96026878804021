import Spinner from "@rio-cloud/rio-uikit/Spinner";
import NoDataState from "../../../../components/common/state/NoDataState";
import { ChartJSON } from "../../types/chart";
import { useIntl } from "react-intl";

interface SummaryMaxSpeedCRProps {
	chart: ChartJSON;
	isLoading: boolean;
}

const SummaryMaxSpeedCR = ({ chart, isLoading }: SummaryMaxSpeedCRProps) => {
	const intl = useIntl();

	if (chart?.chart?.length === 0) {
		return (
			<div className="panel padding-20 height-400">
				<NoDataState />
			</div>
		);
	}

	if (isLoading || !chart) {
		return (
			<div className="panel padding-20 height-400">
				<Spinner />
			</div>
		);
	}

	const allVehicles: any[] = [];
	chart.chart.forEach((dateData) => {
		dateData.axisY.forEach((vehicle) => {
			const existing = allVehicles.find((v: any) => v.name === vehicle.name);
			if (existing) {
				existing.total += parseFloat(vehicle.value);
				existing.count++;
				if (parseFloat(vehicle.value) > existing.maxSpeed) {
					existing.maxSpeed = parseFloat(vehicle.value);
				}
				if (parseFloat(vehicle.value) < existing.minSpeed) {
					existing.minSpeed = parseFloat(vehicle.value);
				}
			} else {
				allVehicles.push({
					name: vehicle.name,
					total: parseFloat(vehicle.value),
					count: 1,
					maxSpeed: parseFloat(vehicle.value),
					minSpeed: parseFloat(vehicle.value),
				});
			}
		});
	});

	const totalValue = allVehicles.reduce((sum, vehicle) => sum + vehicle.total, 0);
	const uniqueVehicleCount = allVehicles.length;
	const averageMaxSpeed = totalValue / allVehicles.reduce((sum, vehicle) => sum + vehicle.count, 0);

	const sortedByMaxSpeed = [...allVehicles].sort((a, b) => b.maxSpeed - a.maxSpeed);
	const highestSpeed = sortedByMaxSpeed[0];
	const lowestSpeed = [...allVehicles].sort((a, b) => a.minSpeed - b.minSpeed)[0];

	const unit = chart.unit == "-" ? "" : chart.unit;
	const mainDataType = intl.formatMessage({ id: `customReport.chart.${chart.mainData}` }) || "Value";

	const formatDate = (dateStr: any) => {
		if (!dateStr) return "N/A";
		const [day, month, year] = dateStr?.split("-");
		return `${day}/${month}/${year}`;
	};

	return (
		<section className="panel padding-20 display-flex flex-column gap-10">
			<div className="display-flex gap-10">
				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Velocidade média máxima</div>
					<p className="text-size-h1 text-medium">
						{averageMaxSpeed.toFixed(0)} {unit}
					</p>
					<p className="text-size-p">
						{`desde ${formatDate(String(chart?.chart[0]?.axisX))} até ${formatDate(String(chart?.chart[chart?.chart?.length - 1].axisX))}`}
					</p>
				</div>

				<div className="callout width-100pct callout-success">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Velocidade máxima</div>
					<p className="text-size-h1 text-medium">{highestSpeed?.name.split(" ")[0]}</p>
					<p className="text-size-p">
						{highestSpeed?.maxSpeed.toFixed(1)} {unit}
					</p>
				</div>

				<div className="callout width-100pct callout-danger">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Velocidade mínima</div>
					<p className="text-size-h1 text-medium">{lowestSpeed?.name.split(" ")[0]}</p>
					<p className="text-size-p">
						{lowestSpeed?.minSpeed.toFixed(1)} {unit}
					</p>
				</div>

				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Veículos monitorados</div>
					<p className="text-size-h1 text-medium">{uniqueVehicleCount}</p>
					<p className="text-size-p">com registros</p>
				</div>
			</div>
			<div className="margin-top-10">
				<table className="table table-hover width-100pct">
					<thead>
						<tr>
							<th>Veículo</th>
							<th>Velocidade Média</th>
							<th>Velocidade Máxima</th>
							<th>% do total</th>
						</tr>
					</thead>
					<tbody>
						{allVehicles
							.sort((a, b) => b.maxSpeed - a.maxSpeed)
							.map((vehicle) => (
								<tr key={vehicle.name}>
									<td>{vehicle.name}</td>
									<td>
										{(vehicle.total / vehicle.count).toFixed(0)}
										{unit}
									</td>
									<td>
										{vehicle.maxSpeed.toFixed(0)}
										{unit}
									</td>
									<td>{((vehicle.total / totalValue) * 100).toFixed(2)}%</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default SummaryMaxSpeedCR;
