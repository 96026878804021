import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown";
import SimpleTooltip from "@rio-cloud/rio-uikit/SimpleTooltip";
import { useAppSelector } from "../../../configuration/setup/hooks";
import { useUpdateSingleChartMutation } from "../../../services/customReportApi";
import { getAppData } from "../../../stores/app/appSlice";
import { getCustomReportPageData } from "../../../stores/customReport/customReportPageSlice";
import { useState } from "react";

const MAIN_DATA_OPTIONS = [
	{ id: "DISTANCE", label: "Distância" },
	{ id: "ENGINE_HOURS", label: "Motor ligado" },
	{ id: "MAX_SPEED", label: "Velocidade máxima" },
	{ id: "FUEL", label: "Combustível" },
	{ id: "AVERAGE_FUEL", label: "Média de combustível" },
	{ id: "TRIP", label: "Viagens" },
	{ id: "HISTOGRAM", label: "Histograma" },
	{ id: "INFRACTIONS", label: "Infrações" },
];

const SUBJECT_OPTIONS = [
	{ id: "DRIVER", label: "Motorista" },
	{ id: "VEHICLE", label: "Veículo" },
];

const COMPLEMENTARY_HISTOGRAM_OPTIONS = [
	{ id: "SPEED", label: "Velocidade" },
	{ id: "RPM", label: "RPM" },
	{ id: "ACCELERATOR", label: "Acelerador" },
	{ id: "ENGINE_BRAKE", label: "Freio motor" },
	{ id: "CRUISE_CONTROL", label: "Controle de cruzeiro" },
	{ id: "TIME_WITH_PTO", label: "Tempo em PTO" },
	{ id: "GEAR", label: "Marcha" },
];

const COMPLEMENTARY_INFRACTION_OPTIONS = [
	{ id: "ALL_INFRACTIONS", label: "Todas infrações" },
	{ id: "IT_KICKDOWN", label: "Arrancada" },
	{ id: "IT_RPM", label: "RPM" },
	{ id: "IT_SPEED", label: "Velocidade" },
	{ id: "IT_SPEED_RAIN", label: "Velocidade na chuva" },
	{ id: "IT_IDLING", label: "Marcha lenta" },
	{ id: "IT_CLUTCH", label: "Embreagem" },
	{ id: "IT_ACCELERATION", label: "Aceleração" },
];

const ChartSubjectSelector = () => {
	const { activeChart } = useAppSelector(getCustomReportPageData);
	const { dateTime } = useAppSelector(getAppData);
	const [updateChart] = useUpdateSingleChartMutation();

	const [openCompl, setOpenCompl] = useState(false);
	const [mainDataToSend, setMainDataToSend] = useState("");

	if (!activeChart) return null;

	const complementaryOptions =
		activeChart.mainData === "HISTOGRAM" && mainDataToSend !== "INFRACTIONS"
			? COMPLEMENTARY_HISTOGRAM_OPTIONS
			: activeChart.mainData === "INFRACTIONS" || mainDataToSend == "INFRACTIONS"
				? COMPLEMENTARY_INFRACTION_OPTIONS
				: [];

	const handleSelectComplementaryData = async (complementaryData: string) => {
		if (mainDataToSend) {
			await handleSelectBoth(mainDataToSend, complementaryData);
			setOpenCompl(false);
			return;
		}
		try {
			const updatedChart = {
				...activeChart,
				complementaryData: complementaryData === "ALL_INFRACTIONS" ? null : complementaryData,
				startAt: dateTime.start,
				endAt: dateTime.end,
			};

			await updateChart([updatedChart]).unwrap();
		} catch (error) {
			console.error("Failed to update chart:", error);
		}
	};

	const handleSelectSubject = async (subject: string) => {
		try {
			const updatedChart = {
				...activeChart,
				subject: subject,
				startAt: dateTime.start,
				endAt: dateTime.end,
			};

			await updateChart([updatedChart]).unwrap();
		} catch (error) {
			console.error("Failed to update chart:", error);
		}
	};

	const handleSelectBoth = async (mainData: string, complementaryData: string) => {
		try {
			const updatedChart = {
				...activeChart,
				chartType: mainData === "HISTOGRAM" || mainData === "INFRACTIONS" ? "BAR" : "LINE",
				mainData: mainData,
				timeRange: mainData === "HISTOGRAM" ? null : activeChart.timeRange == null ? "DAILY" : activeChart.timeRange,
				complementaryData: complementaryData,
				startAt: dateTime.start,
				endAt: dateTime.end,
			};

			await updateChart([updatedChart]).unwrap();
		} catch (error) {
			console.error("Failed to update chart:", error);
		}
	};

	const handleSelectMainData = async (mainData: string) => {
		if (mainData === "INFRACTIONS" || mainData === "HISTOGRAM") {
			setOpenCompl(true);
			setMainDataToSend(mainData);
			return;
		}

		try {
			const updatedChart = {
				...activeChart,
				mainData,
				complementaryData: null,
				chartType: "LINE",
				timeRange: activeChart.timeRange ?? "DAILY",
				startAt: dateTime.start,
				endAt: dateTime.end,
			};

			await updateChart([updatedChart]).unwrap();
			setMainDataToSend("");
			setOpenCompl(false);
		} catch (error) {
			console.error("Failed to update chart:", error);
		}
	};

	const resetComplementaryState = () => {
		setOpenCompl(false);
		setMainDataToSend("");
	};

	const filteredMainDataOptions = MAIN_DATA_OPTIONS.filter(
		(option) => option.id !== activeChart.mainData && !(activeChart.subject === "DRIVER" && option.id === "HISTOGRAM"),
	);

	const filteredComplementaryOptions =
		activeChart.mainData === "HISTOGRAM" || activeChart.mainData === "INFRACTIONS"
			? complementaryOptions.filter((option) => option.id !== activeChart.complementaryData)
			: [];

	const mainDataDropdownItems = filteredMainDataOptions.map((option) => ({
		value: (
			<span className="display-flex gap-5 align-items-center">
				<span>{option.label}</span>
			</span>
		),
		onSelect: () => handleSelectMainData(option.id),
		selected: false,
	}));

	const assuntoDropdownItems = SUBJECT_OPTIONS.map((option) => ({
		value: (
			<span className="display-flex gap-5 align-items-center">
				<span>{option.label}</span>
			</span>
		),
		onSelect: () => handleSelectSubject(option.id),
		selected: false,
	}));

	const complementaryDropdownItems = filteredComplementaryOptions.map((option) => ({
		value: (
			<span className="display-flex gap-5 align-items-center">
				<span>{option.label}</span>
			</span>
		),
		onSelect: () => handleSelectComplementaryData(option.id),
		selected: false,
	}));

	return (
		<div className="display-flex gap-10">
			<SimpleTooltip content="Alterar conteúdo principal">
				<ButtonDropdown
					items={mainDataDropdownItems}
					title={
						<>
							<span className="rioglyph rioglyph-chart-bar" />
							Principal
						</>
					}
				/>
			</SimpleTooltip>
			{complementaryOptions.length > 0 && (
				<SimpleTooltip content="Alterar conteúdo complementar">
					<ButtonDropdown
						items={complementaryDropdownItems}
						open={openCompl}
						onClose={resetComplementaryState}
						onOpen={() => setOpenCompl(true)}
						title={<>Complemento</>}
					/>
				</SimpleTooltip>
			)}
			<SimpleTooltip content={activeChart.mainData == "HISTOGRAM" ? "Histograma não permite mudança" : "Alterar assunto do relatório"}>
				<ButtonDropdown
					disabled={activeChart.mainData == "HISTOGRAM"}
					items={assuntoDropdownItems}
					title={
						<>
							<span className="rioglyph rioglyph-chart-bar" />
							Assunto
						</>
					}
				/>
			</SimpleTooltip>
		</div>
	);
};

export default ChartSubjectSelector;
