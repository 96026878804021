import { Button, ButtonDropdown, Spinner } from "@rio-cloud/rio-uikit";
import { useEffect, useMemo, useState } from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";
import { useIntl } from "react-intl";
import { ChartJSON } from "../../types/chart";
import NoDataState from "../../../../components/common/state/NoDataState";
import { useAppSelector, useAppDispatch } from "../../../../configuration/setup/hooks";
import { getCustomReportPageData, setLabelHidden, setViewByDate } from "../../../../stores/customReport/customReportPageSlice";
import { parseDateString, timeToMinutes, minutesToTime } from "../../../../utils/date";
import { processDataByTimeRange } from "./utils";

interface BarChartProps {
	barChartData: ChartJSON;
}

const formatDate = (dateStr: string): string => {
	try {
		const [day, month, year] = dateStr.split("-");
		return `${day}/${month}`;
	} catch (error) {
		return dateStr;
	}
};

const sortChartDataByDate = (data: any[]): any[] => {
	return data.sort((a, b) => {
		try {
			const dateInfoA = parseDateString(a.name);
			const dateInfoB = parseDateString(b.name);

			if (!dateInfoA || !dateInfoB) return 0;

			const { day: dayA, month: monthA, year: yearA } = dateInfoA;
			const { day: dayB, month: monthB, year: yearB } = dateInfoB;

			const dateA = new Date(yearA, monthA - 1, dayA);
			const dateB = new Date(yearB, monthB - 1, dayB);

			return dateA.getTime() - dateB.getTime();
		} catch (error) {
			console.error("Error sorting dates:", error);
			return 0;
		}
	});
};

const getLabelConfig = (dataLength: number) => {
	if (dataLength <= 10) {
		return { angle: 0, height: 50 };
	}
	if (dataLength > 10 && dataLength <= 30) {
		return { angle: -45, height: 100 };
	}
	return { angle: -45, height: 100 };
};

const BarChartCR = ({ barChartData }: BarChartProps) => {
	const { openFullscreen, timeRangeCR, viewByDate } = useAppSelector(getCustomReportPageData);
	const [displayLimit, setDisplayLimit] = useState(20);
	const [processedChartData, setProcessedChartData] = useState<any>(null);
	const [sortByValue, setSortByValue] = useState(false);
	const [isTimeData, setIsTimeData] = useState(false);
	const intl = useIntl();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (barChartData?.chart?.length > 0 && barChartData.chart[0]?.axisY?.length > 0) {
			const firstValue = barChartData.chart[0].axisY[0].value;
			setIsTimeData(typeof firstValue === "string" && firstValue.includes(":"));
		}
	}, [barChartData]);

	useEffect(() => {
		const processedData = processDataByTimeRange(barChartData, timeRangeCR);
		setProcessedChartData(processedData);
	}, [barChartData, timeRangeCR]);

	if (!barChartData?.chart) {
		return <Spinner />;
	}

	if (barChartData?.chart.length === 0 || barChartData?.chart[0]?.axisY?.length === 0) {
		return <NoDataState />;
	}

	const hasDateXAxis = useMemo(() => {
		return barChartData?.chart.some((item: any) => typeof item.axisX === "string" && item.axisX.includes("-"));
	}, [barChartData]);

	const getRawData = () => {
		if (!barChartData?.chart?.length) return [];

		const dataToUse = processedChartData || barChartData;

		if (!viewByDate) {
			return aggregateDataByName(dataToUse);
		} else {
			return aggregateDataByDate(dataToUse);
		}
	};

	const aggregateDataByName = (dataToUse: any) => {
		const aggregatedData = new Map();

		dataToUse?.chart.forEach((item: { axisY: any[] }) => {
			if (item.axisY) {
				item.axisY.forEach((yItem: { name: any; value: string }) => {
					const name = yItem.name;

					if (name === "Total") return;

					let value: number;

					if (isTimeData) {
						value = timeToMinutes(yItem.value);
					} else {
						value = parseInt(yItem.value, 10);
					}

					if (value > 0) {
						if (!aggregatedData.has(name)) {
							aggregatedData.set(name, {
								name,
								value: 0,
								originalValue: isTimeData ? "00:00" : "0",
							});
						}

						aggregatedData.get(name).value += value;

						if (isTimeData) {
							aggregatedData.get(name).originalValue = minutesToTime(aggregatedData.get(name).value);
						} else {
							aggregatedData.get(name).originalValue = aggregatedData.get(name).value.toString();
						}
					}
				});
			}
		});

		return Array.from(aggregatedData.values()).filter((data) => data.value > 0);
	};

	const aggregateDataByDate = (dataToUse: any) => {
		const dateMap = new Map();

		dataToUse?.chart.forEach((item: { axisX: any; axisY: any[] }) => {
			if (item.axisX && item.axisY) {
				const date = item.axisX;

				let totalValue = 0;
				let originalTotalValue = isTimeData ? "00:00" : "0";

				item.axisY.forEach((yItem: { name: any; value: string }) => {
					if (yItem.name !== "Total") {
						let value: number;

						if (isTimeData) {
							value = timeToMinutes(yItem.value);
						} else {
							value = parseInt(yItem.value, 10);
						}

						if (value > 0) {
							totalValue += value;
						}
					}
				});

				if (isTimeData) {
					originalTotalValue = minutesToTime(totalValue);
				} else {
					originalTotalValue = totalValue.toString();
				}

				if (totalValue > 0) {
					dateMap.set(date, {
						name: date,
						value: totalValue,
						originalValue: originalTotalValue,
					});
				}
			}
		});

		const dataArray = Array.from(dateMap.values());

		if (sortByValue) {
			return dataArray.sort((a, b) => b.value - a.value);
		} else {
			return sortChartDataByDate(dataArray);
		}
	};

	const limitOptions = useMemo(() => {
		const options = [];
		const itemCount = getRawData().length;

		if (itemCount >= 5) options.push({ value: "Top 5", onSelect: () => setDisplayLimit(5) });
		if (itemCount >= 10) options.push({ value: "Top 10", onSelect: () => setDisplayLimit(10) });
		if (itemCount >= 30) options.push({ value: "Top 30", onSelect: () => setDisplayLimit(30) });
		if (itemCount >= 50) options.push({ value: "Top 50", onSelect: () => setDisplayLimit(50) });
		options.push({ value: `Todos (${itemCount})`, onSelect: () => setDisplayLimit(itemCount) });

		return options;
	}, [getRawData]);

	const totalItems = useMemo(() => {
		return getRawData().length;
	}, [barChartData, viewByDate, processedChartData]);

	const processedData = useMemo(() => {
		if (!barChartData?.chart?.length) return [];
		let rawData = getRawData();

		if (!viewByDate) {
			rawData = [...rawData].sort((a, b) => b.value - a.value);
		} else if (viewByDate && sortByValue) {
			rawData = [...rawData].sort((a, b) => b.value - a.value);
		} else if (viewByDate && !sortByValue) {
			rawData = sortChartDataByDate([...rawData]);
		}

		if (rawData.length > 20) {
			dispatch(setLabelHidden(true));
		} else {
			dispatch(setLabelHidden(false));
		}

		return rawData.slice(0, displayLimit);
	}, [barChartData, displayLimit, sortByValue, viewByDate, processedChartData]);

	const toggleViewMode = () => {
		dispatch(setViewByDate(!viewByDate));
	};

	if (openFullscreen && !processedData) {
		return <Spinner />;
	}

	if (!processedData) return <NoDataState />;

	const labelConfig = getLabelConfig(processedData.length);

	const getLabelDate = () => {
		if (!timeRangeCR) return "";

		switch (timeRangeCR.toUpperCase()) {
			case "DAILY":
				return "diário";
			case "MENSAL":
				return "mensal";
			case "WEEKLY":
				return "semanal";
			default:
				return "";
		}
	};

	const formatXAxisLabel = (value: string, timeRangeCR: string, viewByDate: boolean) => {
		if (viewByDate) {
			if (timeRangeCR === "WEEKLY" && value.startsWith("Week")) {
				return value.replace("Week ", "W");
			} else if (timeRangeCR === "MONTHLY") {
				return value;
			} else {
				return formatDate(value);
			}
		} else {
			if (value.startsWith("IT")) {
				return intl.formatMessage({ id: `customReport.chart.${value}` });
			}
		}

		return value.length > 10 && processedData.length > 5 ? `${value.slice(0, 12)}...` : value;
	};

	const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
		if (active) {
			return (
				<div className="custom-tooltip border bg-white padding-10">
					<p className="">{`${label}: ${payload?.[0].value}`}</p>
				</div>
			);
		}

		return null;
	};

	const getIntervalBasedOnData = () => {
		if (openFullscreen) return 0;

		if (processedData.length > 50) {
			return 10;
		} else if (processedData.length > 20) {
			return 1;
		} else {
			return 0;
		}
	};

	return (
		<>
			<div className="display-flex justify-content-between align-items-center margin-bottom-10 margin-left-5">
				<div className="display-flex width-100pct align-items-center flex-wrap">
					<div className="display-flex align-items-center flex-wrap">
						{!viewByDate && (
							<ButtonDropdown
								bsStyle={Button.MUTED}
								title={`Visualização: ${displayLimit === totalItems ? "Todos" : `Top ${displayLimit}`}`}
								items={limitOptions}
							/>
						)}
						{hasDateXAxis && (
							<Button bsStyle={Button.MUTED} onClick={toggleViewMode}>
								{viewByDate ? "Filtrar por categoria" : `Filtrar por ${getLabelDate()}`}
							</Button>
						)}
						{viewByDate && processedData.length > 1 && (
							<Button bsStyle={Button.MUTED} onClick={() => setSortByValue(!sortByValue)}>
								{sortByValue ? "Ordenar por data" : "Ordenar por valor"}
							</Button>
						)}
					</div>
				</div>
				<div className="display-flex text-color-gray justify-content-end gap-4 align-items-center width-100pct">
					<span className="margin-right-10">
						Mostrando {processedData.length} de {totalItems} itens
					</span>
				</div>
			</div>
			<ResponsiveContainer width="100%" height="90%">
				<BarChart data={processedData}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dy={2}
						dataKey="name"
						angle={labelConfig.angle}
						textAnchor={labelConfig.angle !== 0 ? "end" : "middle"}
						interval={getIntervalBasedOnData()}
						height={labelConfig.height}
						tickFormatter={(val) => formatXAxisLabel(val, timeRangeCR, viewByDate)}
						tick={{
							width: processedData.length > 20 && !openFullscreen ? 100 : 0,
							overflow: "ellipsis",
						}}
					/>
					<YAxis
						width={70}
						tickFormatter={(val) => minutesToTime(val)}
						label={{ value: "Tempo em horas", angle: -90, position: "insideLeft" }}
						textAnchor="end"
					/>
					<Tooltip
						content={<CustomTooltip />}
						formatter={(value: any, name: any, props: any) => {
							if (isTimeData) {
								return [props.payload.originalValue || minutesToTime(value), name];
							}
							return [value, name];
						}}
					/>
					<Bar dataKey="value" fill={viewByDate ? "#195761" : "#268897"} maxBarSize={60} />
				</BarChart>
			</ResponsiveContainer>
		</>
	);
};

export default BarChartCR;
