import Spinner from "@rio-cloud/rio-uikit/Spinner";
import NoDataState from "../../../../components/common/state/NoDataState";
import { ChartJSON } from "../../types/chart";

interface SummaryAverageFuelProps {
	chart: ChartJSON;
	isLoading: boolean;
	selectedDate?: string; // Optional date filter from toggle
	selectedLevel?: string; // Optional level filter for histogram
}

const SummaryAverageFuel = ({ chart, isLoading, selectedDate, selectedLevel }: SummaryAverageFuelProps) => {
	if (chart?.chart?.length === 0) {
		return (
			<div className="panel padding-20 height-400">
				<NoDataState />
			</div>
		);
	}

	if (isLoading || !chart) {
		return (
			<div className="panel padding-20 height-400">
				<Spinner />
			</div>
		);
	}

	const filteredChartData = selectedDate ? chart?.chart?.filter((item) => String(item.axisX) === selectedDate) : chart?.chart;

	const fuelByDate = filteredChartData.map((dateData) => {
		const readings = dateData.axisY.map((vehicle) => parseFloat(vehicle.value));
		const totalValue = readings.reduce((sum, value) => sum + value, 0);
		const avgForDate = totalValue / readings.length;

		return {
			date: String(dateData.axisX),
			average: avgForDate,
			readings: readings.length,
		};
	});

	// Calculate average fuel economy across all vehicles and dates
	const allReadings = filteredChartData.flatMap((dateData) => dateData.axisY.map((vehicle) => parseFloat(vehicle.value)));
	const overallAverage = allReadings.reduce((sum, value) => sum + value, 0) / allReadings.length;

	// Find best and worst fuel economy vehicles
	const allVehicles: any = [];
	filteredChartData.forEach((dateData) => {
		dateData.axisY.forEach((vehicle) => {
			const existing = allVehicles.find((v: any) => v.name === vehicle.name);
			if (existing) {
				existing.readings.push(parseFloat(vehicle.value));
				existing.total += parseFloat(vehicle.value);
				existing.count += 1;
			} else {
				allVehicles.push({
					name: vehicle.name,
					readings: [parseFloat(vehicle.value)],
					total: parseFloat(vehicle.value),
					count: 1,
				});
			}
		});
	});

	allVehicles.forEach((vehicle: { average: number; total: number; count: number }) => {
		vehicle.average = vehicle.total / vehicle.count;
	});

	const bestVehicle = allVehicles.sort((a: { average: number }, b: { average: number }) => b.average - a.average)[0];
	const worstVehicle = allVehicles.sort((a: { average: number }, b: { average: number }) => a.average - b.average)[0];

	const uniqueVehicleCount = allVehicles.length;

	// Calculate change percentage between first and second day if available
	let changePercentage = null;
	if (chart.chart.length > 1) {
		const day1Readings = chart.chart[0].axisY.map((v) => parseFloat(v.value));
		const day2Readings = chart.chart[1].axisY.map((v) => parseFloat(v.value));

		const day1Avg = day1Readings.reduce((sum, value) => sum + value, 0) / day1Readings.length;
		const day2Avg = day2Readings.reduce((sum, value) => sum + value, 0) / day2Readings.length;

		if (day1Avg > 0) {
			changePercentage = ((day2Avg - day1Avg) / day1Avg) * 100;
		}
	}

	const formatDate = (dateStr: any) => {
		if (!dateStr) return "N/A";
		const [day, month, year] = dateStr?.split("-");
		return `${day}/${month}/${year}`;
	};

	return (
		<section className="panel padding-20 display-flex flex-column gap-10">
			<div className="display-flex gap-10">
				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Média geral</div>
					<p className="text-size-h1 text-medium">{overallAverage.toFixed(2)} km/l</p>
					<p className="text-size-p">
						{selectedDate
							? `no ${formatDate(selectedDate)}`
							: `desde ${formatDate(String(chart?.chart[0]?.axisX))} até ${formatDate(String(chart?.chart[chart?.chart?.length - 1].axisX))}`}
					</p>
				</div>

				<div className="callout width-100pct callout-success">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Melhor economia</div>
					<p className="text-size-h1 text-medium">{bestVehicle?.average.toFixed(2)} km/l</p>
					<p className="text-size-p">{bestVehicle?.name.split(" ")[0]}</p>
				</div>

				<div className="callout width-100pct callout-warning">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Pior economia</div>
					<p className="text-size-h1 text-medium">{worstVehicle?.average.toFixed(2)} km/l</p>
					<p className="text-size-p">{worstVehicle?.name.split(" ")[0]}</p>
				</div>

				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Veículos monitorados</div>
					<p className="text-size-h1 text-medium">{uniqueVehicleCount}</p>
					<p className="text-size-p">consumo registrado</p>
				</div>
			</div>
			<div className="margin-top-20">
				<table className="table table-hover width-100pct">
					<thead>
						<tr>
							<th>Data</th>
							<th>Média de consumo</th>
							<th>Veículos monitorados</th>
							<th>Melhor economia</th>
							<th>Pior economia</th>
						</tr>
					</thead>
					<tbody>
						{filteredChartData.map((dateData) => {
							const vehicleReadings = dateData.axisY.map((v) => ({
								name: v.name,
								value: parseFloat(v.value),
							}));
							const dailyAverage = vehicleReadings.reduce((sum, v) => sum + v.value, 0) / vehicleReadings.length;
							const highestValue = Math.max(...vehicleReadings.map((v) => v.value));
							const lowestValue = Math.min(...vehicleReadings.map((v) => v.value));
							const bestVehicle = vehicleReadings.find((v) => v.value === highestValue);
							const worstVehicle = vehicleReadings.find((v) => v.value === lowestValue);

							return (
								<tr key={String(dateData.axisX)}>
									<td>{formatDate(String(dateData.axisX))}</td>
									<td>{dailyAverage.toFixed(2)} km/l</td>
									<td>{vehicleReadings.length}</td>
									<td>
										{bestVehicle?.name.split(" ")[0]} ({bestVehicle?.value.toFixed(2)} km/l)
									</td>
									<td>
										{worstVehicle?.name.split(" ")[0]} ({worstVehicle?.value.toFixed(2)} km/l)
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default SummaryAverageFuel;
