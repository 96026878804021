import Spinner from "@rio-cloud/rio-uikit/Spinner";
import NoDataState from "../../../../components/common/state/NoDataState";
import { ChartJSON } from "../../types/chart";

interface SummaryTripsCRProps {
	chart: ChartJSON;
	isLoading: boolean;
	selectedDate?: string;
}

const SummaryTripsCR = ({ chart, isLoading, selectedDate }: SummaryTripsCRProps) => {
	if (chart?.chart?.length === 0) {
		return (
			<div className="panel padding-20 height-400">
				<NoDataState />
			</div>
		);
	}

	if (isLoading || !chart) {
		return (
			<div className="panel padding-20 height-400">
				<Spinner />
			</div>
		);
	}

	const filteredChartData = selectedDate ? chart?.chart?.filter((item) => String(item.axisX) === selectedDate) : chart?.chart;

	const tripsByDate = filteredChartData.map((dateData) => {
		const trips = dateData.axisY.map((vehicle) => parseInt(vehicle.value));
		const totalTrips = trips.reduce((sum, value) => sum + value, 0);
		const avgForDate = totalTrips / trips.length;

		return {
			date: String(dateData.axisX),
			average: avgForDate,
			total: totalTrips,
			vehicles: trips.length,
		};
	});

	// Calculate average trips across all vehicles and dates
	const allTrips = filteredChartData.flatMap((dateData) => dateData.axisY.map((vehicle) => parseInt(vehicle.value)));
	const overallAverage = allTrips.reduce((sum, value) => sum + value, 0) / allTrips.length;
	const totalTrips = allTrips.reduce((sum, value) => sum + value, 0);
	const maxTrips = Math.max(...allTrips);
	const minTrips = Math.min(...allTrips);

	// Find vehicles with most and least trips
	const allVehicles: any = [];
	filteredChartData.forEach((dateData) => {
		dateData.axisY.forEach((vehicle) => {
			const existing = allVehicles.find((v: any) => v.name === vehicle.name);
			if (existing) {
				existing.trips.push(parseInt(vehicle.value));
				existing.total += parseInt(vehicle.value);
				existing.days += 1;
			} else {
				allVehicles.push({
					name: vehicle.name,
					trips: [parseInt(vehicle.value)],
					total: parseInt(vehicle.value),
					days: 1,
				});
			}
		});
	});

	allVehicles.forEach((vehicle: { average: number; total: number; days: number }) => {
		vehicle.average = vehicle.total / vehicle.days;
	});

	const mostActiveVehicle = allVehicles.sort((a: { total: number }, b: { total: number }) => b.total - a.total)[0];
	const leastActiveVehicle = allVehicles.sort((a: { total: number }, b: { total: number }) => a.total - b.total)[0];

	const uniqueVehicleCount = allVehicles.length;

	// Calculate change percentage between first and last day if available
	let changePercentage = null;
	if (filteredChartData.length > 1) {
		const firstDayTrips = filteredChartData[0].axisY.map((v) => parseInt(v.value));
		const lastDayTrips = filteredChartData[filteredChartData.length - 1].axisY.map((v) => parseInt(v.value));

		const firstDayTotal = firstDayTrips.reduce((sum, value) => sum + value, 0);
		const lastDayTotal = lastDayTrips.reduce((sum, value) => sum + value, 0);

		if (firstDayTotal > 0) {
			changePercentage = ((lastDayTotal - firstDayTotal) / firstDayTotal) * 100;
		}
	}

	const formatDate = (dateStr: any) => {
		if (!dateStr) return "N/A";
		const [day, month, year] = dateStr?.split("-");
		return `${day}/${month}/${year}`;
	};

	return (
		<section className="panel padding-20 display-flex flex-column gap-10">
			<div className="display-flex gap-10">
				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Média de viagens</div>
					<p className="text-size-h1 text-medium">{overallAverage.toFixed(1)}</p>
					<p className="text-size-p">
						{selectedDate
							? `no ${formatDate(selectedDate)}`
							: `desde ${formatDate(String(filteredChartData[0]?.axisX))} até ${formatDate(String(filteredChartData[filteredChartData.length - 1].axisX))}`}
					</p>
				</div>

				<div className="callout width-100pct callout-success">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Máximo de viagens</div>
					<p className="text-size-h1 text-medium">{maxTrips}</p>
					<p className="text-size-p">por veículo</p>
				</div>

				<div className="callout width-100pct callout-warning">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Mínimo de viagens</div>
					<p className="text-size-h1 text-medium">{minTrips}</p>
					<p className="text-size-p">por veículo</p>
				</div>

				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Total de viagens</div>
					<p className="text-size-h1 text-medium">{totalTrips}</p>
					<p className="text-size-p">registradas</p>
				</div>
			</div>
			<div className="margin-top-20">
				<table className="table table-hover width-100pct">
					<thead>
						<tr>
							<th>Data</th>
							<th>Total de viagens</th>
							<th>Média por veículo</th>
							<th>Veículos monitorados</th>
							<th>Veículo mais ativo</th>
							<th>Veículo menos ativo</th>
						</tr>
					</thead>
					<tbody>
						{filteredChartData.map((dateData) => {
							const vehicleTrips = dateData.axisY.map((v) => ({
								name: v.name,
								trips: parseInt(v.value),
							}));
							const dailyTotal = vehicleTrips.reduce((sum, v) => sum + v.trips, 0);
							const dailyAverage = dailyTotal / vehicleTrips.length;
							const highestTrips = Math.max(...vehicleTrips.map((v) => v.trips));
							const lowestTrips = Math.min(...vehicleTrips.map((v) => v.trips));
							const mostActiveVehicle = vehicleTrips.find((v) => v.trips === highestTrips);
							const leastActiveVehicle = vehicleTrips.find((v) => v.trips === lowestTrips);

							return (
								<tr key={String(dateData.axisX)}>
									<td>{formatDate(String(dateData.axisX))}</td>
									<td>{dailyTotal}</td>
									<td>{dailyAverage.toFixed(1)}</td>
									<td>{vehicleTrips.length}</td>
									<td>
										{mostActiveVehicle?.name.split(" ")[0]} ({mostActiveVehicle?.trips})
									</td>
									<td>
										{leastActiveVehicle?.name.split(" ")[0]} ({leastActiveVehicle?.trips})
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{changePercentage !== null && (
				<div className="margin-top-10">
					<div className={`callout ${changePercentage >= 0 ? "callout-success" : "callout-warning"}`}>
						<p className="text-size-p">
							{changePercentage >= 0 ? "Aumento" : "Redução"} de {Math.abs(changePercentage).toFixed(1)}% nas viagens entre o primeiro e o
							último dia.
						</p>
					</div>
				</div>
			)}
		</section>
	);
};

export default SummaryTripsCR;
