import { Button, ButtonDropdown, Spinner } from "@rio-cloud/rio-uikit";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/types";
import NoDataState from "../../../components/common/state/NoDataState";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { getCustomReportPageData, setViewByPercentage } from "../../../stores/customReport/customReportPageSlice";
import { minutesToTime, timeToMinutes } from "../../../utils/date";
import { ChartJSON } from "../types/chart";
import { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";

interface HistogramChartProps {
	histogramChartData: ChartJSON;
}

const getLevelNumber = (axisX: string): number => {
	const levelMatch = axisX.match(/duration_lv_(\d+)/);
	if (levelMatch) {
		return parseInt(levelMatch[1], 10);
	}

	const rangeMatch = axisX.match(/duration_(\d+)_(\d+)/);
	if (rangeMatch) {
		return parseInt(rangeMatch[1], 10);
	}

	return -1;
};

const HistogramChartCR = ({ histogramChartData }: HistogramChartProps) => {
	const [histogramDrilldown, setHistogramDrilldown] = useState<any>(null);
	const [histogramBreadcrumb, setHistogramBreadcrumb] = useState<{ level: string; count: number }[]>([]);
	const [displayLimit, setDisplayLimit] = useState(20);
	const [isTimeData, setIsTimeData] = useState(false);
	const [fullVehicleData, setFullVehicleData] = useState<any[]>([]);

	const { openFullscreen, viewByPercentage } = useAppSelector(getCustomReportPageData);
	const dispatch = useAppDispatch();
	const intl = useIntl();

	useEffect(() => {
		if (histogramChartData?.chart?.length > 0 && histogramChartData.chart[0]?.axisY?.length > 0) {
			const firstValue = histogramChartData.chart[0].axisY[0].value;
			setIsTimeData(typeof firstValue === "string" && firstValue.includes(":"));
		}
	}, [histogramChartData]);

	useEffect(() => {
		setHistogramDrilldown(null);
		setHistogramBreadcrumb([]);
	}, [histogramChartData]);

	if (!histogramChartData?.chart) {
		return <Spinner />;
	}

	if (histogramChartData?.chart.length === 0 || histogramChartData?.chart[0]?.axisY?.length === 0) {
		return <NoDataState />;
	}

	const getVehiclesForLevel = (levelId: string, histogramChartData: ChartJSON): any[] => {
		if (!histogramChartData?.chart) return [];

		const levelData = histogramChartData.chart.find((item) => item.axisX === levelId);
		if (!levelData || !levelData.axisY) return [];

		const vehicles = levelData.axisY
			.map((item) => {
				const [hours, minutes] = item.value.split(":").map(Number);
				const totalMinutes = hours * 60 + minutes;

				return {
					name: item.name,
					duration: item.value,
					value: totalMinutes,
					originalValue: item.value,
					level: levelId,
					isTotal: item.name === "Total",
				};
			})
			.sort((a, b) => {
				if (a.isTotal) return -1;
				if (b.isTotal) return 1;
				return b.value - a.value;
			})
			.filter((item) => !item.isTotal); // Add this line to exclude Total entries

		return vehicles;
	};

	const processHistogramChartData = () => {
		const chartData = histogramChartData.chart
			.map((item) => {
				const name = String(item.axisX);
				const totalItem = item.axisY.find((y) => y.name === "Total");
				const timeStr = totalItem ? totalItem.value : item.axisY[0]?.value || "00:00";
				const value = timeToMinutes(timeStr);

				return {
					name,
					value,
					originalValue: timeStr,
					axisX: item.axisX,
					level: item.axisX,
					levelName: name,
					isTotal: !!totalItem,
				};
			})
			.sort((a, b) => {
				const levelA = getLevelNumber(String(a.axisX));
				const levelB = getLevelNumber(String(b.axisX));
				return levelA - levelB;
			});

		if (viewByPercentage) {
			const totalValue = chartData.reduce((sum, item) => sum + item.value, 0);
			return chartData.map((item) => ({
				...item,
				percentage: totalValue > 0 ? (item.value / totalValue) * 100 : 0,
				displayValue: totalValue > 0 ? ((item.value / totalValue) * 100).toFixed(1) + "%" : "0%",
				labelValue: totalValue > 0 ? ((item.value / totalValue) * 100).toFixed(1) + "%" : "0%",
			}));
		}

		return chartData.map((item) => ({
			...item,
			labelValue: isTimeData ? item.originalValue : item.value.toString(),
		}));
	};

	const processVehiclesWithPercentages = (vehicles: any[]) => {
		if (!vehicles || vehicles.length === 0) return [];

		if (viewByPercentage) {
			const totalValue = vehicles.reduce((sum, item) => sum + item.value, 0);
			return vehicles.map((item) => ({
				...item,
				percentage: totalValue > 0 ? (item.value / totalValue) * 100 : 0,
				displayValue: totalValue > 0 ? ((item.value / totalValue) * 100).toFixed(1) + "%" : "0%",
				labelValue: totalValue > 0 ? ((item.value / totalValue) * 100).toFixed(1) + "%" : "0%",
			}));
		}

		return vehicles.map((item) => ({
			...item,
			labelValue: isTimeData ? item.originalValue : item.value.toString(),
		}));
	};

	const displayedData = useMemo(() => {
		if (histogramDrilldown) {
			const limitedData = fullVehicleData.slice(0, displayLimit);
			const processedData = processVehiclesWithPercentages(limitedData);
			return processedData;
		}
		return processHistogramChartData();
	}, [fullVehicleData, displayLimit, viewByPercentage, isTimeData, histogramDrilldown]);

	const handleBarClick = (data: CategoricalChartState) => {
		if (!data.activePayload) return;
		const level = data.activePayload[0].payload.level;
		const levelName = data.activePayload[0].payload.levelName;

		if (histogramDrilldown) return;

		const vehicles = getVehiclesForLevel(level, histogramChartData);
		setFullVehicleData(vehicles);
		setHistogramDrilldown(processVehiclesWithPercentages(vehicles.slice(0, displayLimit)));
		setHistogramBreadcrumb([{ level: levelName, count: vehicles.length }]);
	};

	const handleHistogramBreadcrumbClick = () => {
		setHistogramDrilldown(null);
		setHistogramBreadcrumb([]);
		setFullVehicleData([]);
	};

	const formatXAxisLabel = (value: string) => {
		if (histogramDrilldown) return value;
		return intl.formatMessage({ id: `customReport.chart.${histogramChartData.complementaryData}.${value}` });
	};

	const getLabelConfig = (dataLength: number) => {
		if (dataLength <= 10) {
			return { angle: 0, height: 50 };
		}
		if (dataLength > 10 && dataLength <= 30) {
			return { angle: -45, height: 100 };
		}
		return { angle: -45, height: 100 };
	};

	const handleToggleChange = () => {
		dispatch(setViewByPercentage(!viewByPercentage));
		if (histogramDrilldown && fullVehicleData.length > 0) {
			const limitedData = fullVehicleData.slice(0, displayLimit);
			setHistogramDrilldown(processVehiclesWithPercentages(!viewByPercentage ? limitedData : limitedData));
		}
	};

	const handleLimitChange = (newLimit: number) => {
		setDisplayLimit(newLimit);
		if (histogramDrilldown && fullVehicleData.length > 0) {
			const limitedData = fullVehicleData.slice(0, newLimit);
			setHistogramDrilldown(processVehiclesWithPercentages(limitedData));
		}
	};

	const customLimitOptions = useMemo(() => {
		if (!fullVehicleData.length) return [];

		const options = [];
		const itemCount = fullVehicleData.length;

		if (itemCount >= 5) options.push({ value: "Top 5", onSelect: () => handleLimitChange(5) });
		if (itemCount >= 10) options.push({ value: "Top 10", onSelect: () => handleLimitChange(10) });
		if (itemCount >= 30) options.push({ value: "Top 30", onSelect: () => handleLimitChange(30) });
		if (itemCount >= 50) options.push({ value: "Top 50", onSelect: () => handleLimitChange(50) });
		options.push({ value: `Todos (${itemCount})`, onSelect: () => handleLimitChange(itemCount) });

		return options;
	}, [fullVehicleData]);

	const labelConfig = getLabelConfig(displayedData.length);

	const getYLabel = (complementaryData: string) => {
		return (
			intl.formatMessage({ id: `customReport.chart.${complementaryData}` }) +
			` ` +
			intl.formatMessage({ id: `customReport.chart.${complementaryData}.unit` })
		);
	};

	const shouldShowLabels = displayedData.length <= 30;

	const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
		if (active) {
			return (
				<div className="custom-tooltip border bg-white padding-10">
					<p className="">
						{histogramDrilldown
							? `${label}: ${payload?.[0].payload.labelValue}`
							: `${intl.formatMessage({ id: `customReport.chart.${histogramChartData.complementaryData}.${label}` })}: ${payload?.[0].payload.labelValue}`}
					</p>
				</div>
			);
		}

		return null;
	};

	return (
		<>
			<div className="display-flex justify-content-between align-items-center margin-bottom-10 margin-left-5">
				<div className="display-flex width-100pct align-items-center">
					{histogramDrilldown && (
						<ButtonDropdown
							bsStyle={Button.MUTED}
							title={`Visualização: ${displayLimit === fullVehicleData.length ? "Todos" : `Top ${displayLimit}`}`}
							items={customLimitOptions}
						/>
					)}

					<div className="margin-left-10 display-flex align-items-center">
						<Button bsStyle="muted" checked={viewByPercentage} onClick={handleToggleChange}>
							{viewByPercentage ? "Mostrar por valor" : "Mostrar por percentual"}
						</Button>
					</div>
				</div>

				<div className="display-flex gap-4 align-items-center width-100pct justify-content-center">
					{histogramBreadcrumb.length > 0 && (
						<div className="text-align-center">
							<span className="cursor-pointer text-color-primary margin-right-5" onClick={handleHistogramBreadcrumbClick}>
								Todos níveis
							</span>
							{histogramBreadcrumb.map((crumb, index) => (
								<React.Fragment key={index}>
									<span className="margin-right-5 margin-left-5">/</span>
									<span className="font-weight-bold">
										{intl.formatMessage({ id: `customReport.chart.${histogramChartData.complementaryData}.${crumb.level}` })} ({crumb.count}{" "}
										veículos)
									</span>
								</React.Fragment>
							))}
						</div>
					)}
				</div>

				<div className="display-flex justify-content-end width-100pct">
					{!histogramDrilldown && <span className="margin-right-20">Mostrando {displayedData.length} níveis</span>}

					{histogramDrilldown && (
						<span className="margin-right-20">
							Mostrando {Math.min(displayLimit, fullVehicleData.length)} de {fullVehicleData.length} veículos
						</span>
					)}
				</div>
			</div>

			<ResponsiveContainer width="100%" height="90%">
				<BarChart data={displayedData} onClick={handleBarClick} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dy={2}
						dataKey="name"
						angle={labelConfig.angle}
						textAnchor={labelConfig.angle !== 0 ? "end" : "middle"}
						interval={displayedData.length > 20 && !openFullscreen ? (displayedData.length > 90 ? 5 : 1) : 0}
						height={labelConfig.height}
						tickFormatter={(val) => formatXAxisLabel(val)}
						tick={{
							width: displayedData.length > 20 ? 100 : 0,
							overflow: "ellipsis",
						}}
					/>
					<YAxis
						label={{
							value: getYLabel(histogramChartData.complementaryData),
							angle: -90,
							position: "insideLeft",
							style: {
								textAnchor: "middle",
								fontSize: 12,
								fontWeight: 500,
								fill: "#666",
							},
							dx: -15,
						}}
						tickFormatter={(value) => {
							if (viewByPercentage) {
								return `${value.toFixed(1)}%`;
							} else if (isTimeData) {
								return minutesToTime(value);
							}
							return value;
						}}
					/>
					<Tooltip
						content={CustomTooltip}
						formatter={(value: any, name: any, props: any) => {
							if (viewByPercentage) {
								return [`${props.payload.percentage?.toFixed(1)}%`, name];
							}
							if (isTimeData) {
								return [props.payload.originalValue || minutesToTime(value), name];
							}
							return [value, name];
						}}
					/>
					<Bar
						dataKey={viewByPercentage ? "percentage" : "value"}
						className={!histogramDrilldown ? "cursor-pointer" : undefined}
						fill="#195761"
						maxBarSize={60}
						name="Duration"
					>
						{shouldShowLabels && (
							<LabelList dataKey="labelValue" position="top" fill="#333333" fontSize={12} formatter={(value: any) => value} />
						)}
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</>
	);
};

export default HistogramChartCR;
