import { useIntl } from "react-intl";
import { useAppSelector } from "../../../configuration/setup/hooks";
import { getCustomReportPageData } from "../../../stores/customReport/customReportPageSlice";

interface ChartStripeSoloProps {
	chart: {
		chartName: string;
		id: string;
		mainData: string;
		complementaryData: string;
		subject: string;
	};
	active: boolean;
}

const ChartStripeSolo = ({ chart, active }: ChartStripeSoloProps) => {
	const { activeChart } = useAppSelector(getCustomReportPageData);
	const intl = useIntl();

	if (activeChart && chart.id == activeChart.id) chart = activeChart;

	const chartIconClasses: Record<string, string> = {
		IT_KICKDOWN: "rioglyph-truck text-color-info",
		SPEED: "rioglyph-speed text-color-secondary",
		ENGINE_BRAKE: "rioglyph-brake text-color-warning",
		DISTANCE: "rioglyph-delivery text-color-success",
		RPM: "rioglyph-speed text-color-info",
		TRIP: "rioglyph-warning-sign text-color-secondary",
		GEAR: "rioglyph-merge",
	};

	const getChartIcon = (chartComplement: string, mainData: string) => {
		const iconKey = chartComplement || mainData;
		const iconClass = chartIconClasses[iconKey] || "rioglyph-truck text-color-highlight-dark";
		const [baseIconClass, colorClass] = iconClass.split(" ");

		return `${baseIconClass} ${active ? "text-color-white" : colorClass}`;
	};

	return (
		<div
			className={`padding-25 height-100pct display-flex line-height-20 align-items-center justify-content-between border-right-only border border-top-none border-bottom-none ${active ? "bg-highlight" : ""}`}
		>
			<div className="display-flex flex-column width-100pct">
				<h5>{chart.chartName}</h5>
				<div className="display-flex">
					<div className="space-y-5 text-size-14">
						<div className="display-flex flex-row justify-content-start gap-10">
							<span className={active ? "text-color-white" : "text-color-dark"}>Principal</span>
							<span className={`text-medium ${active ? "text-color-white" : "text-color-gray"}`}>
								{intl.formatMessage({ id: `customReport.chart.${chart.mainData}` })}
							</span>
						</div>
						{chart.complementaryData && (
							<div className="display-flex flex-row justify-content-start gap-10">
								<span className={active ? "text-color-white" : "text-color-dark"}>Complemento</span>
								<span>
									<strong>{intl.formatMessage({ id: `customReport.chart.${chart.complementaryData}` })}</strong>
								</span>
							</div>
						)}
						{chart.subject && (
							<div className="display-flex flex-row justify-content-start gap-10">
								<span className={active ? "text-color-white" : "text-color-dark"}>Assunto</span>
								<span>
									<strong>{intl.formatMessage({ id: `customReport.chart.${chart.subject}` })}</strong>
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
			<span style={{ fontSize: "6rem" }} className={`rioglyph ${getChartIcon(chart.complementaryData, chart.mainData)}`} />
		</div>
	);
};

export default ChartStripeSolo;
