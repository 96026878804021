import { getISOWeek, minutesToTime, parseDateString, timeToMinutes } from "../../../../utils/date";
import { ChartJSON } from "../../types/chart";

export const processDataByTimeRange = (data: ChartJSON, timeRange: string) => {
	if (!data?.chart?.length) return [];

	const processedData = JSON.parse(JSON.stringify(data));

	if (timeRange === "WEEKLY") {
		const weeklyData = groupDataByTimeUnit(processedData.chart, "week", false);
		return { ...processedData, chart: weeklyData };
	} else if (timeRange === "MONTHLY") {
		const monthlyData = groupDataByTimeUnit(processedData.chart, "month", false);
		return { ...processedData, chart: monthlyData };
	}

	return processedData;
};

export const getLevelNumber = (axisX: string): number => {
	const match = axisX.match(/duration_lv_(\d+)/);
	return match ? parseInt(match[1], 10) : -1;
};

export const formatLevel = (axisX: string): string => {
	const levelNum = getLevelNumber(axisX);
	return `Level ${levelNum}`;
};

export const getGroupKey = (date: Date, timeUnit: string): string => {
	if (timeUnit === "week") {
		const weekNumber = getISOWeek(date);
		const year = date.getFullYear();
		return `Week ${weekNumber}, ${year}`;
	} else {
		const monthName = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
		const year = date.getFullYear();
		return `${monthName} ${year}`;
	}
};

const groupDataByTimeUnit = (chartData: any[], timeUnit: string, isTimeData: boolean) => {
	const groupedData = new Map();

	let startDate: Date | null = null;
	let endDate: Date | null = null;

	// Find start and end dates
	chartData.forEach((item) => {
		if (!item.axisX) return;

		const dateInfo = parseDateString(item.axisX);
		if (!dateInfo) return;

		const { day, month, year } = dateInfo;
		const date = new Date(year, month - 1, day);

		if (!startDate || date < startDate) startDate = new Date(date);
		if (!endDate || date > endDate) endDate = new Date(date);
	});

	// Initialize template for grouped data
	if (startDate && endDate && chartData.length > 0 && chartData[0].axisY) {
		const templateYAxis = chartData[0].axisY.map((y: { name: any }) => ({
			name: y.name,
			value: isTimeData ? "00:00" : "0",
		}));

		const currentDate = new Date(startDate);
		const safeEndDate = new Date(endDate);

		// Create template entries for all time periods
		while (currentDate <= safeEndDate) {
			let groupKey = getGroupKey(currentDate, timeUnit);

			if (!groupedData.has(groupKey)) {
				const newYAxis = JSON.parse(JSON.stringify(templateYAxis));
				groupedData.set(groupKey, {
					axisX: groupKey,
					axisY: newYAxis,
				});
			}

			// Advance to next period
			if (timeUnit === "week") {
				currentDate.setDate(currentDate.getDate() + 7);
			} else {
				currentDate.setMonth(currentDate.getMonth() + 1);
			}
		}
	}

	// Aggregate data by time period
	chartData.forEach((item) => {
		if (!item.axisX) return;

		const dateInfo = parseDateString(item.axisX);
		if (!dateInfo) return;

		const { day, month, year } = dateInfo;
		const date = new Date(year, month - 1, day);
		const groupKey = getGroupKey(date, timeUnit);

		if (groupedData.has(groupKey) && item.axisY) {
			item.axisY.forEach((yItem: { value: string }, index: number) => {
				if (groupedData.get(groupKey).axisY[index]) {
					if (isTimeData) {
						// For time values, convert to minutes, sum, then convert back
						const currentMinutes = timeToMinutes(groupedData.get(groupKey).axisY[index].value);
						const additionalMinutes = timeToMinutes(yItem.value);
						const totalMinutes = currentMinutes + additionalMinutes;
						groupedData.get(groupKey).axisY[index].value = minutesToTime(totalMinutes);
					} else {
						// Original numeric handling
						const currentValue = parseInt(groupedData.get(groupKey).axisY[index].value, 10) || 0;
						const additionalValue = parseInt(yItem.value, 10) || 0;
						groupedData.get(groupKey).axisY[index].value = (currentValue + additionalValue).toString();
					}
				}
			});
		}
	});

	return Array.from(groupedData.values());
};
