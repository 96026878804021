import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { getCustomReportPageData, setTypeOfChart } from "../../../stores/customReport/customReportPageSlice";
import { useAppSelector } from "../../../configuration/setup/hooks";

const chartTypes = [
	{ id: "1", label: <FormattedMessage id="customReport.chart.BAR" />, value: "BAR" },
	{ id: "2", label: <FormattedMessage id="customReport.chart.PIE" />, value: "PIE" },
	{ id: "3", label: <FormattedMessage id="customReport.chart.LINE" />, value: "LINE" },
];

const ChartSelector = () => {
	const { typeOfChart, activeChart } = useAppSelector(getCustomReportPageData);
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();

	if (!typeOfChart || !activeChart || !activeChart.chartType || activeChart.mainData == "HISTOGRAM") return;

	const handleChartSelection = (chartType: string) => {
		dispatch(dispatch(setTypeOfChart(chartType)));
		setIsOpen(false);
	};

	const createDropdownItem = (item: { label: string | JSX.Element; id: string; value: string }) => {
		return (
			<li key={item.id} role="presentation" onClick={() => handleChartSelection(item.value)}>
				<a role="menuitem">{item.label}</a>
			</li>
		);
	};

	const customDropdown = chartTypes.map((item) => createDropdownItem(item));

	return (
		<div className="display-flex gap-5 justify-content-start">
			<ButtonDropdown
				open={isOpen}
				bsStyle="muted"
				customDropdown={customDropdown}
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				title={
					<>
						<span>Tipo de gráfico</span>
					</>
				}
			/>
		</div>
	);
};

export default ChartSelector;
