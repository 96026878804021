import { Button, ButtonDropdown, Checkbox, SimpleTooltip } from "@rio-cloud/rio-uikit";
import React, { useMemo, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import NoDataState from "../../../components/common/state/NoDataState";
import { ChartJSON } from "../types/chart";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { getCustomReportPageData, setViewByDate } from "../../../stores/customReport/customReportPageSlice";
import { useIntl } from "react-intl";

interface PieChartCRProps {
	pieChartData: ChartJSON;
}

const MIN_PERCENTAGE_THRESHOLD = 2;

const PieChartCR = ({ pieChartData }: PieChartCRProps) => {
	const { viewByDate } = useAppSelector(getCustomReportPageData);
	const dispatch = useAppDispatch();
	const intl = useIntl();

	const [displayLimit, setDisplayLimit] = useState(20);
	const [drilldownData, setDrilldownData] = useState<any>(null);
	const [breadcrumb, setBreadcrumb] = useState<{ name: string; count: number }[]>([]);
	const [enableAggregation, setEnableAggregation] = useState(true);

	if (!pieChartData?.chart?.[0]?.axisY?.length) {
		return <NoDataState />;
	}

	const hasDateXAxis = useMemo(() => {
		return pieChartData?.chart.some((item) => !!item.axisX && typeof item.axisX == "string");
	}, [pieChartData]);

	const getRawData = () => {
		if (!viewByDate) {
			const categoryMap = new Map<string, number>();

			pieChartData.chart.forEach((chartItem) => {
				chartItem.axisY?.forEach((item) => {
					const name = String(item.name);
					const value = parseInt(item.value, 10);

					if (value > 0) {
						categoryMap.set(name, (categoryMap.get(name) || 0) + value);
					}
				});
			});

			return Array.from(categoryMap.entries())
				.map(([name, value]) => ({ name, value }))
				.filter((item) => item.value > 0)
				.sort((a, b) => b.value - a.value);
		} else {
			const dateMap = new Map();

			pieChartData?.chart.forEach((item) => {
				if (item.axisX && item.axisY) {
					const date = item.axisX as string;

					if (/^\d{2}-\d{2}-\d{4}$/.test(date)) {
						item.axisY.forEach((yItem) => {
							const value = parseInt(yItem.value, 10);

							if (value > 0) {
								if (!dateMap.has(date)) {
									dateMap.set(date, { name: date, value: 0 });
								}

								dateMap.get(date).value += value;
							}
						});
					}
				}
			});

			return Array.from(dateMap.values())
				.sort((a, b) => {
					const [dayA, monthA, yearA] = a.name.split("-").map(Number);
					const [dayB, monthB, yearB] = b.name.split("-").map(Number);

					const dateA = new Date(yearA, monthA - 1, dayA);
					const dateB = new Date(yearB, monthB - 1, dayB);

					return dateA.getTime() - dateB.getTime();
				})
				.filter((item) => item && item.value > 0);
		}
	};

	const limitOptions = useMemo(() => {
		const options = [];
		const itemCount = getRawData().length;

		if (itemCount >= 5) options.push({ value: "Top 5", onSelect: () => setDisplayLimit(5) });
		if (itemCount >= 10) options.push({ value: "Top 10", onSelect: () => setDisplayLimit(10) });
		if (itemCount >= 30) options.push({ value: "Top 30", onSelect: () => setDisplayLimit(30) });
		if (itemCount >= 50) options.push({ value: "Top 50", onSelect: () => setDisplayLimit(50) });
		// options.push({ value: `Todos (${itemCount})`, onSelect: () => setDisplayLimit(itemCount) });

		return options;
	}, [getRawData]);

	const totalItems = useMemo(() => {
		return drilldownData ? drilldownData.length : viewByDate ? getRawData()?.length : pieChartData.chart[0].axisY.length;
	}, [pieChartData, drilldownData, viewByDate]);

	const shouldShowGroupingControls = useMemo(() => {
		const rawData = getRawData();
		return rawData.length > 10;
	}, [pieChartData, viewByDate]);

	const processedData = useMemo(() => {
		let rawData = drilldownData || getRawData();

		const totalValue = rawData.reduce((sum: any, item: { value: any }) => sum + item.value, 0);

		const rawDataWithPercentage = rawData.map((item: { value: number }) => ({
			...item,
			percentage: (item.value / totalValue) * 100,
		}));

		if (!enableAggregation || viewByDate) {
			return rawDataWithPercentage;
		}

		const significantItems = rawDataWithPercentage.filter((item: { percentage: number }) => item.percentage >= MIN_PERCENTAGE_THRESHOLD);
		const smallItems = rawDataWithPercentage.filter((item: { percentage: number }) => item.percentage < MIN_PERCENTAGE_THRESHOLD);

		const smallItemsValue = smallItems.reduce((sum: any, item: { value: any }) => sum + item.value, 0);
		const smallItemsPercentage = (smallItemsValue / totalValue) * 100;

		if (displayLimit === totalItems) {
			return [
				...significantItems,
				{
					name: `Outros (${smallItems.length})`,
					value: smallItemsValue,
					percentage: smallItemsPercentage,
					isAggregated: true,
					hiddenItems: smallItems,
				},
			];
		}

		if (displayLimit < rawData.length) {
			const topItems = rawDataWithPercentage.slice(0, displayLimit);

			if (rawDataWithPercentage.length > displayLimit) {
				const hiddenItems = rawDataWithPercentage.slice(displayLimit);
				const othersValue = hiddenItems.reduce((sum: any, item: { value: any }) => sum + item.value, 0);
				const othersPercentage = (othersValue / totalValue) * 100;

				if (othersPercentage >= 0.5) {
					topItems.push({
						name: `Outros (${hiddenItems.length})`,
						value: othersValue,
						percentage: othersPercentage,
						isAggregated: true,
						hiddenItems: hiddenItems,
					});
				}
			}

			return topItems;
		}

		return rawDataWithPercentage;
	}, [pieChartData, displayLimit, drilldownData, enableAggregation, viewByDate]);

	const toggleViewMode = () => {
		setDrilldownData(null);
		setBreadcrumb([]);
		dispatch(setViewByDate(!viewByDate));
	};

	const handlePieClick = (data: any, index: any) => {
		if (viewByDate) return;

		if (data.isAggregated && data.hiddenItems) {
			setDrilldownData(data.hiddenItems);
			setBreadcrumb([...breadcrumb, { name: data.name, count: data.hiddenItems.length }]);
		}
	};

	const handleBreadcrumbClick = (index: number) => {
		if (index === -1) {
			setDrilldownData(null);
			setBreadcrumb([]);
		} else {
			setBreadcrumb(breadcrumb.slice(0, index + 1));

			if (index === 0) {
				setDrilldownData(null);
			} else {
				const rawData = getRawData();
				setDrilldownData(rawData.slice(displayLimit));
			}
		}
	};

	const formatDate = (dateString: string) => {
		if (!/^\d{2}-\d{2}-\d{4}$/.test(dateString)) return dateString;

		const [day, month, year] = dateString.split("-");
		return `${day}/${month}`;
	};

	const CustomTooltip = ({ active, payload }: any) => {
		if (active && payload && payload.length) {
			const item = payload[0].payload;
			const displayName = item.name.startsWith("IT_") ? intl.formatMessage({ id: `customReport.chart.${item.name}` }) : item.name;

			return (
				<div className="panel padding-10 border-radius-5">
					<p>
						{displayName}: {item.value.toLocaleString()}
					</p>
					<p>Porcentagem: {item.percentage.toFixed(2)}%</p>
					{item.isAggregated && item.hiddenItems && (
						<p className="font-style-italic">
							Contém {item.hiddenItems.length} {item.hiddenItems.length === 1 ? "item" : "items"}
						</p>
					)}
					{item.isAggregated && !viewByDate && <p className="font-size-small text-color-primary">Clicar para drilldown</p>}
				</div>
			);
		}
		return null;
	};

	const visibleItems = useMemo(() => {
		const aggregatedItems = processedData.filter((item: any) => item.isAggregated);
		if (aggregatedItems.length > 0) {
			return processedData.length - aggregatedItems.length;
		}
		return processedData.length;
	}, [processedData]);

	const hasItemsBelowThreshold = useMemo(() => {
		const rawData = getRawData();
		if (rawData.length <= 1) return false;

		const totalValue = rawData.reduce((sum, item) => sum + item.value, 0);
		return rawData.some((item) => (item.value / totalValue) * 100 < MIN_PERCENTAGE_THRESHOLD);
	}, [pieChartData, viewByDate]);

	const categoryColors = [
		"#0088FE",
		"#00C49F",
		"#FFBB28",
		"#FF8042",
		"#8884D8",
		"#82CA9D",
		"#FF7300",
		"#A569BD",
		"#5DADE2",
		"#58D68D",
		"#F4D03F",
		"#EB984E",
		"#EC7063",
		"#AF7AC5",
		"#45B39D",
	];

	const dateColors = ["#0D4A52", "#176975", "#238293", "#34B7C5", "#A1E6ED"];

	return (
		<div className="display-flex flex-column height-100pct width-100pct">
			<div className="display-flex justify-content-between margin-bottom-10 margin-left-5">
				<div className="display-flex align-items-center flex-wrap">
					{shouldShowGroupingControls && !viewByDate && (
						<>
							<ButtonDropdown
								bsStyle={Button.MUTED}
								title={`Visualização: ${displayLimit === totalItems ? "Todos" : `Top ${displayLimit}`}`}
								items={limitOptions}
							/>
						</>
					)}
					{hasDateXAxis && (
						<Button bsStyle={Button.MUTED} onClick={toggleViewMode} className="margin-right-10">
							{viewByDate ? "Filtrar por categoria" : "Filtrar por data"}
						</Button>
					)}
					{!viewByDate && hasItemsBelowThreshold && (
						<SimpleTooltip content="Itens com porcentagem abaixo de 5%">
							<Checkbox
								id="enable-aggregation"
								checked={enableAggregation}
								onChange={() => setEnableAggregation(!enableAggregation)}
								label="Agrupar itens"
							/>
						</SimpleTooltip>
					)}
				</div>
				<div className="display-flex flex-column gap-4 align-items-start">
					{breadcrumb.length > 0 && !viewByDate && (
						<div className="margin-bottom-10">
							<span className="cursor-pointer text-color-primary margin-right-5" onClick={() => handleBreadcrumbClick(-1)}>
								Padrão
							</span>
							{breadcrumb.map((crumb, index) => (
								<React.Fragment key={index}>
									<span className="margin-right-5 margin-left-5">/</span>
									<span
										className={`cursor-pointer ${index === breadcrumb.length - 1 ? "font-weight-bold" : "text-color-primary"}`}
										onClick={() => handleBreadcrumbClick(index)}
									>
										{crumb.name} ({crumb.count} items)
									</span>
								</React.Fragment>
							))}
						</div>
					)}

					<span className="margin-right-10 text-color-gray">
						Mostrando {visibleItems} de {totalItems} items
					</span>
				</div>
			</div>
			<ResponsiveContainer width="100%" height="100%">
				<PieChart>
					<Tooltip content={<CustomTooltip />} />
					<Pie
						data={processedData}
						dataKey="value"
						nameKey="name"
						cx="50%"
						cy="50%"
						innerRadius={60}
						outerRadius={110}
						fill="#8884d8"
						label={({ name, percentage }) => {
							const displayName = viewByDate ? formatDate(name) : name;
							const shortName = displayName.length > 15 ? `${displayName.slice(0, 12)}...` : displayName;

							if (shortName.startsWith("IT_")) {
								return `${intl.formatMessage({ id: `customReport.chart.${shortName}` })} (${percentage.toFixed(1)}%)`;
							} else {
								return `${shortName}: ${percentage.toFixed(1)}%`;
							}
						}}
						labelLine={true}
						onClick={handlePieClick}
						cursor={viewByDate ? "default" : "pointer"}
						minAngle={5}
						paddingAngle={1}
					>
						{processedData.map((entry: any, index: number) => {
							const colorScheme = viewByDate ? dateColors : categoryColors;
							const randomIndex = Math.floor(Math.random() * colorScheme.length);

							return (
								<Cell
									key={`cell-${index}`}
									fill={entry.isAggregated ? "#CCCCCC" : colorScheme[randomIndex]}
									stroke="#fff"
									strokeWidth={entry.isAggregated ? 2 : 1}
								/>
							);
						})}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default PieChartCR;
