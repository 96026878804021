import Spinner from "@rio-cloud/rio-uikit/Spinner";
import NoDataState from "../../../../components/common/state/NoDataState";
import { ChartJSON } from "../../types/chart";
import { useIntl } from "react-intl";

interface SummaryRPMCRProps {
	chart?: ChartJSON;
	isLoading: boolean;
}

const SummaryRPMCR = ({ chart, isLoading }: SummaryRPMCRProps) => {
	const intl = useIntl();

	if (chart?.chart?.length === 0 || !chart) {
		return (
			<div className="panel padding-20 height-400">
				<NoDataState />
			</div>
		);
	}

	if (isLoading) {
		return (
			<div className="panel padding-20 height-400">
				<Spinner />
			</div>
		);
	}

	// Convert time string to seconds
	const parseTimeToSeconds = (timeStr: string): number => {
		if (!timeStr || timeStr === "N/A" || timeStr === "00:00") return 0;

		const [minutes, seconds] = timeStr.split(":").map(Number);
		return minutes * 60 + seconds;
	};

	const formatRPMRange = (rangeStr: string) => {
		const rpmMapping: { [key: string]: string } = {
			duration_501_600: "customReport.chart.RPM.duration_501_600",
			duration_601_700: "customReport.chart.RPM.duration_601_700",
			duration_701_800: "customReport.chart.RPM.duration_701_800",
			duration_801_900: "customReport.chart.RPM.duration_801_900",
			duration_901_1000: "customReport.chart.RPM.duration_901_1000",
			duration_1001_1100: "customReport.chart.RPM.duration_1001_1100",
			duration_1101_1200: "customReport.chart.RPM.duration_1101_1200",
			duration_1201_1300: "customReport.chart.RPM.duration_1201_1300",
			duration_1301_1400: "customReport.chart.RPM.duration_1301_1400",
			duration_1401_1500: "customReport.chart.RPM.duration_1401_1500",
			duration_1501_1600: "customReport.chart.RPM.duration_1501_1600",
			duration_1601_1700: "customReport.chart.RPM.duration_1601_1700",
			duration_1701_1800: "customReport.chart.RPM.duration_1701_1800",
			duration_1801_1900: "customReport.chart.RPM.duration_1801_1900",
			duration_1901_2000: "customReport.chart.RPM.duration_1901_2000",
			duration_2001_2100: "customReport.chart.RPM.duration_2001_2100",
			duration_2101_2200: "customReport.chart.RPM.duration_2101_2200",
			duration_2201_2300: "customReport.chart.RPM.duration_2201_2300",
			duration_2301_2400: "customReport.chart.RPM.duration_2301_2400",
			duration_2401_2500: "customReport.chart.RPM.duration_2401_2500",
			duration_2501_2600: "customReport.chart.RPM.duration_2501_2600",
			duration_2601_2700: "customReport.chart.RPM.duration_2601_2700",
			duration_2701_2800: "customReport.chart.RPM.duration_2701_2800",
			duration_2801_2900: "customReport.chart.RPM.duration_2801_2900",
			duration_2901_3000: "customReport.chart.RPM.duration_2901_3000",
			duration_3001_3100: "customReport.chart.RPM.duration_3001_3100",
		};
		return rpmMapping[rangeStr] || rangeStr;
	};

	const processedData = chart.chart.map((entry) => {
		const vehicleData = entry.axisY.filter((item) => item.name !== "Total" && parseTimeToSeconds(item.value) > 0);

		const totalTime = parseTimeToSeconds(entry.axisY.find((item) => item.name === "Total")?.value || "00:00");

		return {
			rpmRange: intl.formatMessage({ id: formatRPMRange(String(entry.axisX)) }),
			rpmRangeKey: entry.axisX,
			totalTime,
			vehicles: vehicleData.map((vehicle) => ({
				name: vehicle.name,
				time: parseTimeToSeconds(vehicle.value),
			})),
		};
	});

	const grandTotalTime = processedData.reduce((sum, entry) => sum + entry.totalTime, 0);

	const averageTimePerRange = grandTotalTime / processedData.length;

	return (
		<section className="panel padding-20 display-flex flex-column gap-10">
			<div className="display-flex gap-10">
				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">
						Tempo médio por faixa de RPM
					</div>
					<p className="text-size-h1 text-medium">{(averageTimePerRange / 60).toFixed(2)} min</p>
					<p className="text-size-p">tempo médio em cada faixa de RPM</p>
				</div>

				<div className="callout width-100pct callout-success">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Faixa de RPM mais frequente</div>
					<p className="text-size-h1 text-medium">{processedData.length > 0 ? processedData[0].rpmRange : "N/A"}</p>
					<p className="text-size-p">
						{processedData.length > 0
							? `${(processedData[0].totalTime / 60).toFixed(2)} min (${((processedData[0].totalTime / grandTotalTime) * 100).toFixed(2)}%)`
							: "Sem dados"}
					</p>
				</div>

				<div className="callout width-100pct callout-warning">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">
						Faixa de RPM menos frequente
					</div>
					<p className="text-size-h1 text-medium">{processedData.length > 0 ? processedData[processedData.length - 1].rpmRange : "N/A"}</p>
					<p className="text-size-p">
						{processedData.length > 0
							? `${(processedData[processedData.length - 1].totalTime / 60).toFixed(2)} min (${((processedData[processedData.length - 1].totalTime / grandTotalTime) * 100).toFixed(2)}%)`
							: "Sem dados"}
					</p>
				</div>

				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Faixas de RPM</div>
					<p className="text-size-h1 text-medium">{processedData.length}</p>
					<p className="text-size-p">faixas de RPM registradas</p>
				</div>
			</div>

			<div className="margin-top-20">
				<table className="table table-hover width-100pct">
					<thead>
						<tr>
							<th>Faixa de RPM</th>
							<th>Tempo Total</th>
							<th>Percentual</th>
						</tr>
					</thead>
					<tbody>
						{processedData.map((entry) => (
							<tr key={String(entry.rpmRangeKey)}>
								<td>{entry.rpmRange}</td>
								<td>{(entry.totalTime / 60).toFixed(2)} min</td>
								<td>{((entry.totalTime / grandTotalTime) * 100).toFixed(2)}%</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default SummaryRPMCR;
