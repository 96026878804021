import Spinner from "@rio-cloud/rio-uikit/Spinner";
import NoDataState from "../../../../components/common/state/NoDataState";
import { ChartJSON } from "../../types/chart";
import { useIntl } from "react-intl";

interface SummaryCRHistogramProps {
	chart?: ChartJSON;
	isLoading: boolean;
}

const SummaryCRHistogram = ({ chart, isLoading }: SummaryCRHistogramProps) => {
	const intl = useIntl();

	if (chart?.chart?.length === 0 || !chart) {
		return (
			<div className="panel padding-20 height-400">
				<NoDataState />
			</div>
		);
	}

	if (isLoading) {
		return (
			<div className="panel padding-20 height-400">
				<Spinner />
			</div>
		);
	}

	const parseTimeToHours = (timeStr: string): { hours: number; minutes: number } => {
		if (!timeStr || timeStr === "N/A" || timeStr === "00:00") return { hours: 0, minutes: 0 };

		const timeParts = timeStr.split(":");
		if (timeParts.length === 2) {
			const [hours, minutes] = timeParts.map(Number);
			return { hours, minutes };
		}

		return { hours: 0, minutes: 0 };
	};

	const processedData = chart.chart.map((entry) => {
		const vehicleData = entry.axisY.filter(
			(item) => item.name !== "Total" && (parseTimeToHours(item.value).hours > 0 || parseTimeToHours(item.value).minutes > 0),
		);

		const totalTime = parseTimeToHours(entry.axisY.find((item) => item.name === "Total")?.value || "00:00");

		return {
			rpmRange: String(entry.axisX),
			rangeKey: entry.axisX,
			totalTime,
			vehicles: vehicleData.map((vehicle) => ({
				name: vehicle.name,
				time: parseTimeToHours(vehicle.value),
			})),
		};
	});

	const formatAverageTime = (processedData: any[]) => {
		if (processedData.length === 1) {
			const totalTime = processedData[0].totalTime;
			return `${totalTime.hours}h ${totalTime.minutes}min`;
		}

		const grandTotalTime = processedData.reduce((sum, entry) => {
			const hours = entry.totalTime.hours;
			const minutes = entry.totalTime.minutes;
			return sum + hours + minutes / 60;
		}, 0);

		const averageTimePerRange = grandTotalTime / processedData.length;
		const averageHours = Math.floor(averageTimePerRange);
		const averageMinutes = Math.round((averageTimePerRange % 1) * 60);

		return `${averageHours}h ${averageMinutes}min`;
	};

	const calculateTotalTimeInHours = (time: { hours: number; minutes: number }) => time.hours + time.minutes / 60;

	const grandTotalTime = processedData.reduce((sum, entry) => sum + calculateTotalTimeInHours(entry.totalTime), 0);

	return (
		<section className="panel padding-20 display-flex flex-column gap-10">
			<div className="display-flex gap-10">
				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Tempo médio</div>
					<p className="text-size-h1 text-medium">{formatAverageTime(processedData)}</p>
					<p className="text-size-p">tempo médio em cada faixa</p>
				</div>

				{processedData.length > 1 ? (
					<>
						<div className="callout width-100pct callout-success">
							<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Mais frequente</div>
							<p className="text-size-h1 text-medium">
								{intl.formatMessage({ id: `customReport.chart.${chart.complementaryData}.${processedData[0].rpmRange}` })}
							</p>
							<p className="text-size-p">
								{`${processedData[0].totalTime.hours}h ${processedData[0].totalTime.minutes}min (${((calculateTotalTimeInHours(processedData[0].totalTime) / grandTotalTime) * 100).toFixed(2)}%)`}
							</p>
						</div>

						<div className="callout width-100pct callout-warning">
							<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Menos frequente</div>
							<p className="text-size-h1 text-medium">
								{intl.formatMessage({
									id: `customReport.chart.${chart.complementaryData}.${processedData[processedData.length - 1].rpmRange}`,
								})}
							</p>
							<p className="text-size-p">
								{`${processedData[processedData.length - 1].totalTime.hours}h ${processedData[processedData.length - 1].totalTime.minutes}min (${((calculateTotalTimeInHours(processedData[processedData.length - 1].totalTime) / grandTotalTime) * 100).toFixed(2)}%)`}
							</p>
						</div>
					</>
				) : (
					<div className="callout width-100pct callout-success">
						<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Única faixa</div>
						<p className="text-size-h1 text-medium">
							{intl.formatMessage({ id: `customReport.chart.${chart.complementaryData}.${processedData[0].rpmRange}` })}
						</p>
						<p className="text-size-p">{`${processedData[0].totalTime.hours}h ${processedData[0].totalTime.minutes}min (100%)`}</p>
					</div>
				)}

				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Número de faixas</div>
					<p className="text-size-h1 text-medium">{processedData.length}</p>
					<p className="text-size-p">número diferentes de faixas registradas</p>
				</div>
			</div>

			<div className="margin-top-20">
				<table className="table table-hover width-100pct">
					<thead>
						<tr>
							<th>Faixa</th>
							<th>Tempo Total</th>
							<th>Percentual</th>
						</tr>
					</thead>
					<tbody>
						{processedData.map((entry) => (
							<tr key={String(entry.rangeKey)}>
								<td>{intl.formatMessage({ id: `customReport.chart.${chart.complementaryData}.${entry.rpmRange}` })}</td>
								<td>
									{entry.totalTime.hours}h {entry.totalTime.minutes}min
								</td>
								<td>{((calculateTotalTimeInHours(entry.totalTime) / grandTotalTime) * 100).toFixed(1)}%</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default SummaryCRHistogram;
