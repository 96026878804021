import ContentLoader from "@rio-cloud/rio-uikit/ContentLoader";
import classNames from "classnames";
import NoDataState from "../../../components/common/state/NoDataState";
import { useAppSelector } from "../../../configuration/setup/hooks";
import { getCustomReportPageData } from "../../../stores/customReport/customReportPageSlice";
import { ChartJSON } from "../types/chart";
import { useIntl } from "react-intl";

interface TableCRProps {
	item: ChartJSON;
	isLoading: boolean;
}

const timeToMinutes = (timeStr: string): number => {
	if (!timeStr || typeof timeStr !== "string") return 0;

	const parts = timeStr.split(":");
	if (parts.length !== 2) return 0;

	const hours = parseInt(parts[0], 10);
	const minutes = parseInt(parts[1], 10);
	const seconds = 0;

	return hours * 60 + minutes + seconds / 60;
};

const TableCR = ({ item, isLoading }: TableCRProps) => {
	const { viewByDate, viewByPercentage } = useAppSelector(getCustomReportPageData);
	const intl = useIntl();

	const tableClassNames = classNames(
		"table rounded panel",
		"table-column-overflow-hidden",
		"table-bordered",
		"table-sticky",
		"table-head-filled",
	);

	const cellClassName = "overflow-hidden white-space-nowrap hyphens-auto";

	if (isLoading || !item) {
		return (
			<table className="panel height-100pct table rounded margin-0 border-none">
				<tbody className="flex flex-column justify-content-center align-items-center">
					{[...Array(10)].map((_, rowNumber) => (
						<tr key={rowNumber}>
							{[...Array(2)].map((_, columnNumber) => (
								<td key={columnNumber}>
									<ContentLoader width="50%" height="13px">
										<rect width="100%" height="100%" />
									</ContentLoader>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		);
	}

	if (item?.chart?.length == 0 || item?.chart[0].axisY.length == 0)
		return (
			<div className="panel height-100pct">
				<NoDataState />
			</div>
		);

	const calculateTotal = (data: any[]): number => {
		return data.reduce((sum, item) => sum + (parseInt(item.value, 10) || 0), 0);
	};

	if (item?.mainData === "HISTOGRAM") {
		const getLevelNumber = (axisX: string): number => {
			const levelMatch = axisX.match(/duration_lv_(\d+)/);
			if (levelMatch) return parseInt(levelMatch[1], 10);

			const rangeMatch = axisX.match(/duration_(\d+)_(\d+)/);
			if (rangeMatch) return parseInt(rangeMatch[1], 10);

			return -1;
		};

		const sortedChart = [...item.chart].sort((a, b) => {
			return getLevelNumber(String(a.axisX)) - getLevelNumber(String(b.axisX));
		});

		const total = sortedChart.reduce((sum, chartItem) => {
			const totalItem = chartItem.axisY.find((item: any) => item.name === "Total");
			return sum + (totalItem ? timeToMinutes(totalItem.value) : 0);
		}, 0);

		return (
			<table className={tableClassNames}>
				<thead className="rounded">
					<tr>
						<th className={cellClassName}>Nível</th>
						<th className={cellClassName}>Duração</th>
						{viewByPercentage && <th className={cellClassName}>Percentual</th>}
					</tr>
				</thead>
				<tbody className="overflow-hidden height-500">
					{sortedChart.map((chartItem: any, index: number) => {
						const totalItem = chartItem.axisY.find((item: any) => item.name === "Total");
						const itemValue = totalItem ? timeToMinutes(totalItem.value) : 0;
						const percentage = total > 0 ? ((itemValue / total) * 100).toFixed(1) : "0.00";

						return (
							<tr key={index}>
								<td className={cellClassName}>
									{intl.formatMessage({ id: `customReport.chart.${item.complementaryData}.${chartItem.axisX}` })}
								</td>
								<td className={cellClassName}>{totalItem ? totalItem.value : "-"}</td>
								{viewByPercentage && <td className={cellClassName}>{percentage}%</td>}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	} else if (item?.mainData === "INFRACTIONS") {
		const vehicleTotals = new Map();
		item.chart.forEach((chartItem) => {
			chartItem.axisY.forEach((vehicle) => {
				const currentValue = parseInt(vehicle.value, 10) || 0;
				const existingValue = vehicleTotals.get(vehicle.name) || 0;
				vehicleTotals.set(vehicle.name, existingValue + currentValue);
			});
		});

		const sortedVehicles = Array.from(vehicleTotals.entries())
			.sort((a, b) => b[1] - a[1])
			.map(([name, value]) => ({ name, value }));

		const total = calculateTotal(sortedVehicles);

		return (
			<table className={tableClassNames}>
				<thead>
					<tr>
						<th className={cellClassName}>{item.mainData == "INFRACTIONS" ? "Infrações" : "Veículo"}</th>
						<th className={cellClassName}>Infrações</th>
						{viewByPercentage && <th className={cellClassName}>Percentual</th>}
					</tr>
				</thead>
				<tbody className="overflow-y-scroll overflow-hidden height-500">
					{sortedVehicles.map((vehicle, index) => {
						const percentage = total > 0 ? ((vehicle.value / total) * 100).toFixed(1) : "0.00";
						return (
							<tr key={index}>
								<td className={cellClassName}>
									{vehicle.name.startsWith("IT") ? intl.formatMessage({ id: `customReport.chart.${vehicle.name}` }) : "Veículo"}
								</td>
								<td className={cellClassName}>{vehicle.value}</td>
								{viewByPercentage && <td className={cellClassName}>{percentage}%</td>}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	} else if (viewByDate) {
		const sortedChartByDate = [...item.chart].sort((a, b) => {
			const totalValueA = a.axisY?.reduce((sum, item) => sum + timeToMinutes(item.value), 0) ?? 0;
			const totalValueB = b.axisY?.reduce((sum, item) => sum + timeToMinutes(item.value), 0) ?? 0;
			return totalValueB - totalValueA;
		});

		const total = sortedChartByDate.reduce((sum, chartItem) => {
			const itemTotal = chartItem.axisY?.reduce((itemSum, axisYItem) => itemSum + timeToMinutes(axisYItem.value), 0) ?? 0;
			return sum + itemTotal;
		}, 0);

		return (
			<table className={tableClassNames}>
				<thead>
					<tr>
						<th className={cellClassName}>Data</th>
						<th className={cellClassName}>Total</th>
						{viewByPercentage && <th className={cellClassName}>Percentual</th>}
					</tr>
				</thead>
				<tbody className="height-500 overflow-y-scroll overflow-hidden">
					{sortedChartByDate.map((chartItem: any, index: number) => {
						const itemTotal = chartItem.axisY?.reduce((sum: number, axisYItem: any) => sum + timeToMinutes(axisYItem.value), 0) ?? 0;
						const percentage = total > 0 ? ((itemTotal / total) * 100).toFixed(1) : "0.00";

						return (
							<tr key={index}>
								<td className={cellClassName}>{chartItem.axisX}</td>
								<td className={cellClassName}>
									{chartItem.axisY?.map((axisYItem: any) => `${axisYItem.name}: ${axisYItem.value}`).join(", ")}
								</td>
								{viewByPercentage && <td className={cellClassName}>{percentage}%</td>}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	} else {
		const allAxisY = item.chart.flatMap((chartItem) => chartItem.axisY || []);

		const sortedAxisY = [...allAxisY].sort((a, b) => {
			const isTimeA = typeof a.value === "string" && a.value.includes(":");
			const isTimeB = typeof b.value === "string" && b.value.includes(":");

			if (isTimeA && isTimeB) {
				return timeToMinutes(b.value) - timeToMinutes(a.value);
			} else {
				const valueA = parseInt(String(a.value || 0), 10);
				const valueB = parseInt(String(b.value || 0), 10);
				return valueB - valueA;
			}
		});

		const total = calculateTotal(sortedAxisY);

		return (
			<table className={tableClassNames}>
				<thead>
					<tr>
						<th className={cellClassName}>{item.mainData == "INFRACTIONS" ? "Infrações" : "Veículo"}</th>
						<th className={cellClassName}>Valor</th>
						{viewByPercentage && <th className={cellClassName}>Percentual</th>}
					</tr>
				</thead>
				<tbody className="overflow-y-scroll overflow-hidden">
					{sortedAxisY.map((axisYItem: any, idx: number) => {
						const itemValue = parseInt(String(axisYItem.value || 0), 10);
						const percentage = total > 0 ? ((itemValue / total) * 100).toFixed(2) : "0.00";

						return (
							<tr key={idx}>
								<td className={cellClassName}>{axisYItem.name}</td>
								<td className={cellClassName}>{axisYItem.value}</td>
								{viewByPercentage && <td className={cellClassName}>{percentage}%</td>}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}
};

export default TableCR;
