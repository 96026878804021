import {
	differenceInDays,
	differenceInHours,
	endOfDay,
	endOfMonth,
	endOfToday,
	endOfWeek,
	format,
	getYear,
	isSameDay,
	startOfDay,
	startOfMonth,
	startOfToday,
	startOfWeek,
	startOfYear,
	subDays,
	subHours,
} from "date-fns";

interface DateRange {
	start: Date | string;
	end: Date | string;
}

export const INIT_TODAY = startOfToday();
export const END_TODAY = endOfToday();
const YEAR = getYear(INIT_TODAY);
const getStartOfYear = (year: number) => startOfYear(new Date(year, 0, 1));

export function getTodayPeriod(): DateRange {
	const today = new Date();
	return { start: startOfDay(today), end: today };
}

export function getYesterdayPeriod(): DateRange {
	const yesterday = subDays(new Date(), 1);
	return { start: startOfDay(yesterday), end: endOfDay(yesterday) };
}

export function getLast15DaysPeriod(): DateRange {
	const last15DaysStart = subDays(new Date(), 15);
	const today = new Date();
	return { start: startOfDay(last15DaysStart), end: endOfDay(today) };
}

export function getLastWeekPeriod(): DateRange {
	const lastWeekStart = startOfWeek(subDays(new Date(), 7));
	const lastWeekEnd = endOfWeek(subDays(new Date(), 7));
	return { start: startOfDay(lastWeekStart), end: endOfDay(lastWeekEnd) };
}

export function getLastMonthPeriod(): DateRange {
	const lastMonthStart = startOfMonth(subDays(new Date(), 30));
	const lastMonthEnd = endOfMonth(subDays(new Date(), 30));
	return { start: startOfDay(lastMonthStart), end: endOfDay(lastMonthEnd) };
}

export function getEndAt(): string {
	return format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
}

export function getStartAt(daysAgo: number): string {
	return format(subDays(new Date(), daysAgo), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
}

export function getLastYearToTodayPeriod(): DateRange {
	return {
		start: startOfDay(getStartOfYear(YEAR)),
		end: endOfDay(END_TODAY),
	};
}

export function formatDateObjectToString(dateObject: any): string {
	const formattedStartDate = format(dateObject.start, "EEE dd/MM/yy HH:mm");
	const formattedEndDate = format(dateObject.end, "EEE dd/MM/yy HH:mm");

	return `${formattedStartDate} - ${formattedEndDate}`;
}

export const isJanuaryFirst = (date: any) => {
	return date.month() === 0 && date.date() === 1;
};

export function getLast24HoursPeriod(): DateRange {
	const last24HoursStart = subDays(new Date(), 1);
	const currentDate = new Date();

	const formattedStartDate = format(last24HoursStart, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
	const formattedEndDate = format(currentDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

	return { start: formattedStartDate, end: formattedEndDate };
}

export function remove3HoursFromDate(date: string): Date {
	return subHours(date, 3);
}

export function formatDateToCustomFormat(date: Date | string): string {
	const dateObj = new Date(date);
	const adjustedDate = subHours(dateObj, 3);
	return format(adjustedDate, "dd/MM/yyyy HH:mm");
}

export function getDifferenceInDaysOrHours(startDate: Date | string, endDate: Date | string): string {
	const start = new Date(startDate);
	const end = new Date(endDate);

	if (isSameDay(start, end)) {
		const hoursDifference = differenceInHours(end, start);
		return `${hoursDifference} horas`;
	} else {
		const daysDifference = differenceInDays(end, start);
		return `${daysDifference} dias`;
	}
}

export const timeToMinutes = (timeStr: string): number => {
	if (!timeStr || typeof timeStr !== "string") return 0;

	const parts = timeStr.split(":");
	if (parts.length !== 2) return 0;

	const hours = parseInt(parts[0], 10);
	const minutes = parseInt(parts[1], 10);

	return hours * 60 + minutes;
};

export const minutesToTime = (minutes: number): string => {
	const hours = Math.floor(minutes / 60);
	const mins = minutes % 60;
	return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
};

export const parseDateString = (dateStr: string): { day: number; month: number; year: number } | null => {
	try {
		const [day, month, year] = dateStr.split("-").map(Number);
		return { day, month, year };
	} catch (error) {
		console.error("Error parsing date:", dateStr, error);
		return null;
	}
};

export const getISOWeek = (date: Date) => {
	const d = new Date(date);
	d.setHours(0, 0, 0, 0);
	d.setDate(d.getDate() + 4 - (d.getDay() || 7));
	const yearStart = new Date(d.getFullYear(), 0, 1);
	return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
};

export const formatDate = (dateStr: string): string => {
	try {
		const [day, month, year] = dateStr.split("-");
		if (year && year.length === 4) {
			return `${day}/${month}`;
		} else if (year) {
			return `${day}/${month}/${year}`;
		} else {
			return `${day}/${month}`;
		}
	} catch (error) {
		return dateStr;
	}
};
