const NoDataState = () => {
	return (
		<div className="width-100pct text-center flex-column gap-10 height-100pct display-flex align-items-center justify-content-center">
			<span className="rioglyph rioglyph-exclamation-sign text-size-h1"></span>
			<h5>Sem dados para esse período</h5>
		</div>
	);
};

export default NoDataState;
