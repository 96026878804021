import { Button, ButtonDropdown, Spinner } from "@rio-cloud/rio-uikit";
import React, { useMemo, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import DateSelector from "../../../components/common/date/DateSelector";
import NoDataState from "../../../components/common/state/NoDataState";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { getCustomReportPageData, setLabelHidden } from "../../../stores/customReport/customReportPageSlice";
import { ChartJSON } from "../types/chart";
import { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";

interface LineChartCRProps {
	lineChartData: ChartJSON;
}

const LineChartCR = ({ lineChartData }: LineChartCRProps) => {
	const { openFullscreen } = useAppSelector(getCustomReportPageData);
	const [displayLimit, setDisplayLimit] = useState(20);
	const [drilldownData, setDrilldownData] = useState<any>(null);
	const [breadcrumb, setBreadcrumb] = useState<{ name: string; count: number }[]>([]);
	const [viewByDate, setViewByDate] = useState(false);
	const dispatch = useAppDispatch();

	if (lineChartData?.chart[0]?.axisY?.length == 0 || !lineChartData || lineChartData?.chart?.length == 0) {
		return <NoDataState />;
	}

	const hasDateXAxis = useMemo(() => {
		return lineChartData?.chart.some((item) => typeof item.axisX == "string");
	}, [lineChartData]);

	const getRawData = () => {
		if (!viewByDate) {
			const categoryMap = new Map<string, number>();

			lineChartData?.chart.forEach((item) => {
				item.axisY.forEach((yItem) => {
					const name = yItem.name;
					const value = parseInt(yItem.value, 10);

					if (value > 0) {
						categoryMap.set(name, (categoryMap.get(name) || 0) + value);
					}
				});
			});

			return Array.from(categoryMap.entries())
				.map(([name, value]) => ({ name, value }))
				.filter((data) => data.value > 0)
				.sort((a, b) => b.value - a.value);
		} else {
			const dateMap = new Map();

			lineChartData?.chart.forEach((item) => {
				if (item.axisX && item.axisY) {
					const date = item.axisX;

					item.axisY.forEach((yItem) => {
						const value = parseInt(yItem.value, 10);

						if (value > 0) {
							if (!dateMap.has(date)) {
								dateMap.set(date, { name: date, value: 0 });
							}

							dateMap.get(date).value += value;
						}
					});
				}
			});

			return Array.from(dateMap.values()).sort((a, b) => {
				const [dayA, monthA, yearA] = a.name.split("-").map(Number);
				const [dayB, monthB, yearB] = b.name.split("-").map(Number);

				const dateA = new Date(yearA, monthA - 1, dayA);
				const dateB = new Date(yearB, monthB - 1, dayB);

				return dateA.getTime() - dateB.getTime();
			});
		}
	};

	const limitOptions = useMemo(() => {
		const options = [];
		const itemCount = getRawData().length;

		if (itemCount >= 5) options.push({ value: "Top 5", onSelect: () => setDisplayLimit(5) });
		if (itemCount >= 10) options.push({ value: "Top 10", onSelect: () => setDisplayLimit(10) });
		if (itemCount >= 30) options.push({ value: "Top 30", onSelect: () => setDisplayLimit(30) });
		if (itemCount >= 50) options.push({ value: "Top 50", onSelect: () => setDisplayLimit(50) });
		options.push({ value: `Todos (${itemCount})`, onSelect: () => setDisplayLimit(itemCount) });

		return options;
	}, [getRawData]);

	const totalItems = useMemo(() => {
		return drilldownData ? drilldownData.length : getRawData()?.length;
	}, [lineChartData, drilldownData, viewByDate]);

	const processedData = useMemo(() => {
		if (!lineChartData?.chart?.length) return [];

		let rawData = drilldownData || getRawData();

		const totalValue = rawData.reduce((sum: any, item: { value: any }) => sum + item.value, 0);

		const rawDataWithPercentage = rawData.map((item: { value: number }) => ({
			...item,
			percentage: (item.value / totalValue) * 100,
		}));

		if (displayLimit < rawData.length && displayLimit !== totalItems) {
			return rawDataWithPercentage.slice(0, displayLimit);
		}

		if (rawDataWithPercentage.length > 20) {
			dispatch(setLabelHidden(true));
		} else {
			dispatch(setLabelHidden(false));
		}

		return rawDataWithPercentage;
	}, [lineChartData, displayLimit, drilldownData, viewByDate]);

	const handleBreadcrumbClick = (index: number) => {
		if (index === -1) {
			setDrilldownData(null);
			setBreadcrumb([]);
		} else {
			setBreadcrumb(breadcrumb.slice(0, index + 1));

			if (index === 0) {
				setDrilldownData(null);
			} else {
				const rawData = getRawData();
				setDrilldownData(rawData.slice(displayLimit));
			}
		}
	};

	const toggleViewMode = () => {
		setDrilldownData(null);
		setBreadcrumb([]);
		setViewByDate(!viewByDate);
	};

	const visibleItems = useMemo(() => {
		return processedData.length;
	}, [processedData]);

	const getLabelConfig = () => {
		if (processedData.length <= 10) {
			return {
				angle: 0,
				height: 50,
			};
		}
		if (processedData.length > 10 && processedData.length <= 30) {
			return {
				angle: -45,
				height: 100,
			};
		}
		return {
			angle: -45,
			height: 100,
		};
	};

	const labelConfig = getLabelConfig();

	if (openFullscreen && !processedData) {
		return <Spinner />;
	}

	const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
		if (active) {
			return (
				<div className="custom-tooltip border bg-white padding-10">
					<p className="">{`${label}: ${payload?.[0].value}`}</p>
				</div>
			);
		}

		return null;
	};

	const getIntervalBasedOnData = () => {
		if (openFullscreen) return 0;

		if (processedData.length > 50) {
			return 10;
		} else if (processedData.length > 20) {
			return 1;
		} else {
			return 0;
		}
	};

	return (
		<>
			{openFullscreen && (
				<div className="margin-x-20 max-width-350">
					<DateSelector />
				</div>
			)}
			<div className="display-flex justify-content-between align-items-center margin-bottom-10 margin-left-5">
				<div className="display-flex align-items-center width-100pct gap-4">
					{!viewByDate && (
						<ButtonDropdown
							bsStyle={Button.MUTED}
							title={`Visualização: ${displayLimit === totalItems ? "All" : `Top ${displayLimit}`}`}
							items={limitOptions}
						/>
					)}

					{hasDateXAxis && (
						<Button bsStyle={Button.MUTED} onClick={toggleViewMode} className="margin-right-10">
							{viewByDate ? "Filtrar por categoria" : "Filtrar por data"}
						</Button>
					)}

					{!viewByDate && (
						<span className="width-100pct margin-left-10 text-color-gray text-right margin-right-10">
							Mostrando {visibleItems} de {totalItems} itens
						</span>
					)}
				</div>

				{breadcrumb.length > 0 && !viewByDate && (
					<div className="margin-bottom-10">
						<span className="cursor-pointer text-color-primary margin-right-5" onClick={() => handleBreadcrumbClick(-1)}>
							Padrão
						</span>
						{breadcrumb.map((crumb, index) => (
							<React.Fragment key={index}>
								<span className="margin-right-5 margin-left-5">/</span>
								<span
									className={`cursor-pointer ${index === breadcrumb.length - 1 ? "font-weight-bold" : "text-color-primary"}`}
									onClick={() => handleBreadcrumbClick(index)}
								>
									{crumb.name} ({crumb.count} items)
								</span>
							</React.Fragment>
						))}
					</div>
				)}
			</div>
			<ResponsiveContainer width="100%" height="90%">
				<LineChart data={processedData}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dy={2}
						dataKey="name"
						angle={labelConfig.angle}
						textAnchor={labelConfig.angle !== 0 ? "end" : "middle"}
						interval={getIntervalBasedOnData()}
						height={labelConfig.height}
						tickFormatter={(value) => {
							if (viewByDate) {
								const [day, month] = value.split("-");
								return `${month}/${day}`;
							}
							return value.length > (openFullscreen ? 15 : 10) && processedData.length > 5 ? `${value.slice(0, 7)}...` : value;
						}}
						tick={{
							width: processedData.length > 20 && !openFullscreen ? 100 : 0,
							overflow: "ellipsis",
						}}
					/>
					<YAxis />
					<Tooltip content={<CustomTooltip />} />
					<Line type="monotone" dataKey="value" stroke={viewByDate ? "#195761" : "#268897"} strokeWidth={3} activeDot={{ r: 8 }} />
				</LineChart>
			</ResponsiveContainer>
		</>
	);
};

export default LineChartCR;
