import { ButtonDropdown, CustomState, Dialog, Notification, ResponsiveColumnStripe, SimpleTooltip, Spinner } from "@rio-cloud/rio-uikit";
import { useEffect, useState } from "react";
import DateSelector from "../../components/common/date/DateSelector";
import { useAppDispatch, useAppSelector } from "../../configuration/setup/hooks";
import { useDeleteSingleChartMutation, useGetSingleChartByIdMutation, useLazyGetListChartsIdsQuery } from "../../services/customReportApi";
import { getAppData } from "../../stores/app/appSlice";
import { getCustomReportPageData, setActiveChart, setTimeRangeCR } from "../../stores/customReport/customReportPageSlice";
import ActiveChart from "./active/ActiveChart";
import ChartActions from "./customizer/ChartActions";
import CreateChartForm from "./form/CreateChartForm";
import DuplicateChartForm from "./form/DuplicateChartForm";
import EditChartForm from "./form/EditChartForm";
import ChartStripeSolo from "./selectors/ChartStripeSolo";
import ChartSubjectSelector from "./selectors/ChartSubjectSelector";
import SummaryCRHistogram from "./stats/histogram/SummaryHistogramCR";
import SummaryInfractionsCR from "./stats/infractions/SummaryInfractionsCR";
import SummaryAverageFuel from "./stats/normal/SummaryAverageFuelCR";
import SummaryDistanceCR from "./stats/normal/SummaryDistanceCR";
import SummaryEngineHoursCR from "./stats/normal/SummaryEngineHoursCR";
import SummaryGearCR from "./stats/normal/SummaryGearCR";
import SummaryMaxSpeedCR from "./stats/normal/SummaryMaxSpeedCR";
import SummaryRPMCR from "./stats/normal/SummaryRPMCR";
import SummarySpeedCR from "./stats/normal/SummarySpeedCR";
import SummaryTripsCR from "./stats/normal/SummaryTripsCR";
import TableCR from "./table/TableCR";
import { ChartJSON } from "./types/chart";

const CustomReportPage = () => {
	const { assets, tags, dateTime } = useAppSelector(getAppData);
	const { activeChart, chartsFetched } = useAppSelector(getCustomReportPageData);

	const [getSingleChartById, { data: singleChart, isLoading: isLoadingSingleChart }] = useGetSingleChartByIdMutation();
	const [deleteSingleChart, { isSuccess }] = useDeleteSingleChartMutation();
	const [getListCharts, { isLoading }] = useLazyGetListChartsIdsQuery();

	const [openCreateChartDialog, setOpenCreateChartDialog] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [showConfirmChartDelete, setConfirmChartDelete] = useState(false);
	const [duplicateConfirm, setDuplicateConfirm] = useState(false);

	const dispatch = useAppDispatch();

	const isHistogram = activeChart && activeChart.mainData == "HISTOGRAM";

	const chartsPerPage = 3;
	const activeChartIndex = chartsFetched?.findIndex((chart: ChartJSON) => chart.id === activeChart?.id);
	const activePage = activeChartIndex !== -1 ? Math.floor(activeChartIndex / chartsPerPage) : 0;

	useEffect(() => {
		const payload = {
			assetIds: assets,
			tagIds: tags,
			startAt: dateTime.start,
			endAt: dateTime.end,
			zone: "UTC-3",
			infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
			page: 0,
			pageSize: 50,
		};
		getListCharts(payload);
	}, []);

	useEffect(() => {
		if (chartsFetched && chartsFetched.length > 0 && (!activeChart || Object.keys(activeChart).length === 0)) {
			dispatch(setActiveChart(chartsFetched[0]));
		}
	}, [chartsFetched, activeChart, dispatch]);

	useEffect(() => {
		if (activeChart && Object.keys(activeChart).length > 0) {
			handleFetchSingleChart();
		}
	}, [activeChart, dateTime, assets, tags]);

	if (isLoading) return <Spinner />;

	const handleFetchSingleChart = async () => {
		if (!activeChart) return;
		await getSingleChartById({
			customId: activeChart.id,
			requestPayload: {
				assetIds: assets,
				tagIds: tags,
				startAt: dateTime.start,
				endAt: dateTime.end,
				zone: "UTC-3",
				infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
				search: "",
				page: 0,
				pageSize: 50,
			},
		});
	};

	const handleDeleteChart = async (chartId: string) => {
		try {
			await deleteSingleChart(activeChart).unwrap();
			if (isSuccess) {
				Notification.success("Gráfico deletado");
				const remainingCharts = chartsFetched?.filter((chart: ChartJSON) => chart.id !== chartId);
				if (remainingCharts && remainingCharts.length > 0) {
					dispatch(setActiveChart(remainingCharts[0]));
				} else {
					dispatch(setActiveChart(null));
				}
			}
		} catch (error) {
			Notification.error("Erro ao deletar gráfico");
		}
	};

	const handleChangeTimeChart = (timeRange: string) => {
		dispatch(setTimeRangeCR(timeRange));
	};

	const calendarChartItems = [
		{
			value: (
				<span className="display-flex gap-5 align-items-center">
					<span>Diário</span>
				</span>
			),
			onSelect: () => handleChangeTimeChart("DAILY"),
		},
		{
			value: (
				<span className="display-flex gap-5 align-items-center">
					<span>Semanal</span>
				</span>
			),
			onSelect: () => handleChangeTimeChart("WEEKLY"),
		},
		{
			value: (
				<span className="display-flex gap-5 align-items-center">
					<span>Mensal</span>
				</span>
			),
			onSelect: () => handleChangeTimeChart("MONTHLY"),
		},
	];

	const chartOptions = [
		{
			value: (
				<span className="display-flex gap-5 align-items-center">
					<span className="rioglyph rioglyph-pencil" />
					<span>Editar</span>
				</span>
			),
			onSelect: () => setOpenEditDialog(true),
		},
		{
			value: (
				<span className="display-flex gap-5 align-items-center">
					<span className="rioglyph rioglyph-trash" />
					<span>Deletar</span>
				</span>
			),
			onSelect: () => setConfirmChartDelete(true),
		},
		{
			value: (
				<span className="display-flex gap-5 align-items-center">
					<span className="rioglyph rioglyph-duplicate" />
					<span>Duplicar</span>
				</span>
			),
			onSelect: () => setDuplicateConfirm(true),
		},
	];

	const SUMMARY_COMPONENTS: any = {
		DEFAULT: SummaryCRHistogram,
		AVERAGE_FUEL: SummaryAverageFuel,
		GEAR: SummaryGearCR,
		TRIPS: SummaryTripsCR,
		FUEL: SummaryAverageFuel,
		ENGINE_HOURS: SummaryEngineHoursCR,
		MAX_SPEED: SummaryMaxSpeedCR,
		DISTANCE: SummaryDistanceCR,
		SPEED: SummarySpeedCR,
		RPM: SummaryRPMCR,
	};

	const renderSummaryComponent = () => {
		if (singleChart.mainData == "HISTOGRAM") {
			return <SummaryCRHistogram chart={singleChart} isLoading={isLoadingSingleChart} />;
		} else if (singleChart.mainData == "INFRACTIONS") {
			return <SummaryInfractionsCR chart={singleChart} isLoading={isLoadingSingleChart} />;
		} else {
			const SummaryComponent = SUMMARY_COMPONENTS[singleChart.mainData] || SUMMARY_COMPONENTS["DEFAULT"];
			return <SummaryComponent chart={singleChart} isLoading={isLoadingSingleChart} />;
		}
	};

	return (
		<section className="display-flex flex-column gap-15 padding-bottom-50 padding-top-25">
			<ChartActions
				showConfirmChartDelete={showConfirmChartDelete}
				handleDeleteChart={handleDeleteChart}
				setConfirmChartDelete={setConfirmChartDelete}
				setOpenCreateChartDialog={setOpenCreateChartDialog}
			/>
			{chartsFetched?.length > 0 ? (
				<ResponsiveColumnStripe
					className="bg-white rounded"
					columnsWrapperClassName="margin-0 padding-0 space-x--1 user-select-none"
					buttonClassName="hover-bg-highlight-decent"
					minColumnWith={220}
					minColumns={1}
					maxColumns={3}
					stretchLastItems={false}
					asType="ul"
					activePage={activePage}
					disableAnimation
				>
					{chartsFetched?.map((item: ChartJSON, index: number) => (
						<div key={item.id} className="cursor-pointer min-width-400" onClick={() => dispatch(setActiveChart(item))}>
							<ChartStripeSolo chart={item} active={activeChart?.id ? item.id === activeChart.id : index === 0} />
						</div>
					))}
				</ResponsiveColumnStripe>
			) : null}
			{chartsFetched.length == 0 ? (
				<>
					<CustomState
						outerClassName="position-absolute top-30pct shadow-default"
						headline={"Cria relatórios customizados"}
						message={"Veja a sua frota e suas informações"}
						icons={[
							{ name: "rioglyph rioglyph-truck-baseline", color: "text-color-highlight" },
							{ name: "rioglyph rioglyph-detail-view-info", color: "text-color-info" },
							{ name: "rioglyph rioglyph-cog", color: "text-color-status-driving" },
						]}
					/>
				</>
			) : (
				<>
					<div className="display-flex justify-content-between height-35">
						<div className="gap-4 display-flex">
							{!isHistogram && (
								<SimpleTooltip content="Padrão de tempo do relatório">
									<ButtonDropdown
										bsStyle="primary"
										title={<span className="rioglyph rioglyph-calendar" />}
										items={calendarChartItems}
										iconOnly
									/>
								</SimpleTooltip>
							)}
							<DateSelector />
						</div>
						<div className="display-flex gap-5">
							<SimpleTooltip content="Ações do relatório">
								<ButtonDropdown title={<span className="rioglyph rioglyph-pencil" />} items={chartOptions} iconOnly />
							</SimpleTooltip>
							<ChartSubjectSelector />
						</div>
					</div>

					<div className="display-flex flex-column flex-row-lg gap-10 height-800 height-500-lg">
						<ActiveChart singleChart={singleChart} isLoadingSingleChart={isLoadingSingleChart} />
						<div className="width-100pct panel rounded overflow-hidden overflow-y-scroll height-500 height-100pct-md width-25pct-lg">
							<TableCR item={singleChart} isLoading={isLoadingSingleChart} />
						</div>
					</div>

					{singleChart && renderSummaryComponent()}
				</>
			)}
			{openCreateChartDialog && (
				<Dialog
					bsSize={Dialog.SIZE_SM}
					showCloseButton={true}
					onClose={() => setOpenCreateChartDialog(false)}
					show={openCreateChartDialog}
					title={"Criar relatório customizado"}
					body={<CreateChartForm setOpenCreateChartDialog={setOpenCreateChartDialog} />}
				/>
			)}
			{openEditDialog && activeChart && (
				<Dialog
					bsSize={Dialog.SIZE_SM}
					title={"Atualizar relatório customizado"}
					showCloseButton={true}
					onClose={() => setOpenEditDialog(false)}
					show={openEditDialog}
					body={<EditChartForm chart={activeChart} />}
				/>
			)}
			{duplicateConfirm && activeChart && (
				<Dialog
					bsSize={Dialog.SIZE_SM}
					title={"Duplicar relatório customizado"}
					showCloseButton={true}
					onClose={() => setDuplicateConfirm(false)}
					show={duplicateConfirm}
					body={<DuplicateChartForm setDuplicateConfirm={setDuplicateConfirm} />}
				/>
			)}
		</section>
	);
};

export default CustomReportPage;
