import Spinner from "@rio-cloud/rio-uikit/Spinner";
import NoDataState from "../../../../components/common/state/NoDataState";
import { ChartJSON } from "../../types/chart";
import { useIntl } from "react-intl";

interface SummaryEngineHoursCRProps {
	chart: ChartJSON;
	isLoading: boolean;
}

const SummaryEngineHoursCR = ({ chart, isLoading }: SummaryEngineHoursCRProps) => {
	const intl = useIntl();

	if (chart?.chart?.length === 0) {
		return (
			<div className="panel padding-20 height-400">
				<NoDataState />
			</div>
		);
	}

	if (isLoading || !chart) {
		return (
			<div className="panel padding-20 height-400">
				<Spinner />
			</div>
		);
	}

	const convertToMinutes = (timeString: string): number => {
		if (!timeString) return 0;
		const [hours, minutes] = timeString.split(":").map(Number);
		return hours * 60 + minutes;
	};

	const convertToHoursMinutes = (totalMinutes: number): string => {
		const hours = Math.floor(totalMinutes / 60);
		const minutes = Math.floor(totalMinutes % 60);
		return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
	};

	const allVehicles: any[] = [];
	chart.chart.forEach((dateData) => {
		dateData.axisY.forEach((vehicle) => {
			const durationInMinutes = convertToMinutes(vehicle.value);
			const existing = allVehicles.find((v: any) => v.name === vehicle.name);
			if (existing) {
				existing.total += durationInMinutes;
				existing.count++;
				if (durationInMinutes > existing.maxDuration) {
					existing.maxDuration = durationInMinutes;
					existing.maxDurationFormatted = vehicle.value;
				}
				if (durationInMinutes < existing.minDuration) {
					existing.minDuration = durationInMinutes;
					existing.minDurationFormatted = vehicle.value;
				}
			} else {
				allVehicles.push({
					name: vehicle.name,
					total: durationInMinutes,
					count: 1,
					maxDuration: durationInMinutes,
					maxDurationFormatted: vehicle.value,
					minDuration: durationInMinutes,
					minDurationFormatted: vehicle.value,
				});
			}
		});
	});

	const totalMinutes = allVehicles.reduce((sum, vehicle) => sum + vehicle.total, 0);
	const uniqueVehicleCount = allVehicles.length;
	const totalCount = allVehicles.reduce((sum, vehicle) => sum + vehicle.count, 0);
	const averageDurationMinutes = totalCount > 0 ? totalMinutes / totalCount : 0;
	const averageDurationFormatted = convertToHoursMinutes(averageDurationMinutes);

	const sortedByMaxDuration = [...allVehicles].sort((a, b) => b.maxDuration - a.maxDuration);
	const longestDuration = sortedByMaxDuration[0];
	const shortestDuration = [...allVehicles].sort((a, b) => a.minDuration - b.minDuration)[0];

	const unit = chart.unit === "-" ? "" : chart.unit;

	const formatDate = (dateStr: any) => {
		if (!dateStr) return "N/A";
		const [day, month, year] = dateStr?.split("-");
		return `${day}/${month}/${year}`;
	};

	const truncateVehicleName = (name: string, maxLength = 10) => {
		if (!name) return "N/A";
		const firstPart = name.split(" ")[0];
		return firstPart.length > maxLength ? firstPart.substring(0, maxLength) + "..." : firstPart;
	};

	return (
		<section className="panel padding-20 display-flex flex-column gap-10">
			<div className="display-flex gap-10">
				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Duração média</div>
					<p className="text-size-h1 text-medium">
						{averageDurationFormatted} {unit}
					</p>
					<p className="text-size-p">
						{`desde ${formatDate(String(chart?.chart[0]?.axisX))} até ${formatDate(String(chart?.chart[chart?.chart?.length - 1].axisX))}`}
					</p>
				</div>

				<div className="callout width-100pct callout-success">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Duração máxima</div>
					<p className="text-size-h1 text-medium">{truncateVehicleName(longestDuration?.name.split(" ")[0])}</p>
					<p className="text-size-p">
						{longestDuration?.maxDurationFormatted} {unit}
					</p>
				</div>

				<div className="callout width-100pct callout-danger">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Duração mínima</div>
					<p className="text-size-h1 text-medium">{truncateVehicleName(shortestDuration?.name.split(" ")[0])}</p>
					<p className="text-size-p">
						{shortestDuration?.minDurationFormatted} {unit}
					</p>
				</div>

				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Veículos monitorados</div>
					<p className="text-size-h1 text-medium">{uniqueVehicleCount}</p>
					<p className="text-size-p">com registros</p>
				</div>
			</div>
			<div className="margin-top-10">
				<table className="table table-hover width-100pct">
					<thead>
						<tr>
							<th>Veículo</th>
							<th>Duração Média</th>
							<th>Duração Máxima</th>
							<th>% do total</th>
						</tr>
					</thead>
					<tbody>
						{allVehicles
							.sort((a, b) => b.maxDuration - a.maxDuration)
							.map((vehicle) => (
								<tr key={vehicle.name}>
									<td>{vehicle.name}</td>
									<td>
										{convertToHoursMinutes(vehicle.total / vehicle.count)} {unit}
									</td>
									<td>
										{vehicle.maxDurationFormatted} {unit}
									</td>
									<td>{((vehicle.total / totalMinutes) * 100).toFixed(2)}%</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default SummaryEngineHoursCR;
