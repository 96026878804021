/* eslint-disable camelcase, no-console */
import {
	type UserProfile as Profile,
	User,
	UserManager,
	type UserManagerSettings,
	WebStorageStateStore,
	InMemoryWebStorage,
} from "oidc-client-ts";
import join from "lodash/fp/join";

import { mapUserProfile } from "./userProfile";
import { config } from "../../config";
import type { OAuthConfig } from "..";
import type { AccessToken } from "../tokenHandling/tokenSlice";
import type { UserProfile } from "./loginSlice";

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
	userManager.signinSilent().catch((error: Error) => {
		if (error.message === "login_required") {
			oauthConfig.onSessionExpired();
		} else {
			setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
		}
	});
};

export type SessionRenewedResult = {
	accessToken: AccessToken;
	idToken: Profile;
	profile: UserProfile;
	locale: string;
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
	accessToken: result.access_token,
	idToken: result.profile,
	locale: result.profile?.locale ?? "en-GB",
	profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
	const redirectUri = config.login.redirectUri;
	const silentRedirectUri = config.login.silentRedirectUri;

	const settings: UserManagerSettings = {
		authority: `${config.login.authority}`,
		client_id: `${config.login.clientId}`,
		loadUserInfo: false,
		redirect_uri: `${redirectUri}`,
		response_type: "code",
		scope: join(" ", config.login.oauthScope),
		silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
		includeIdTokenInSilentRenew: false,
		automaticSilentRenew: true,
		monitorSession: true,
		staleStateAgeInSeconds: 600,
		userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
		filterProtocolClaims: false,
	};

	return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
	userManager.events.addUserLoaded((user) => {
		oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
	});

	userManager.events.addUserUnloaded(() => {
		oauthConfig.onSessionExpired();
	});

	userManager.events.addSilentRenewError(() => {
		retrySigninSilent(oauthConfig, userManager);
	});

	userManager.events.addUserSignedOut(() => {
		oauthConfig.onSessionExpired();
	});

	return userManager;
};

export const configureMockUserManager = (oauthConfig: OAuthConfig): UserManager => {
	const signinSilent = () => {
		const userSettings = {
			// manual token
			access_token:
				"eyJraWQiOiJjMDNmNDMwMy05OTk4LTRkYmItYjgxNC03OGI0MmIwNmE0NDkiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJwcm9kLXJpby11c2Vyczo3ZGYzNDY0MS03NzNmLTRlYjUtYmM1ZS1iMTE1MGE5OGIwNjgiLCJhenAiOiJjNTMyNDc4MC1kY2NlLTQ5ZWMtYjBmNS0zMzJmM2UwOGVjNzgiLCJzY29wZSI6ImFzc2V0LWFkbWluaXN0cmF0aW9uLnJlYWQgbWFwLnJlYWQgcGhvbmUgb3BlbmlkIGRyaXZlcnMucmVhZCBwcm9maWxlIHRhZ3MucmVhZCBlbWFpbCIsImlzcyI6Imh0dHBzOi8vYXV0aC5pYW0ucmlvLmNsb3VkIiwiZXhwIjoxNzQzNjEyNjMwLCJpYXQiOjE3NDM2MDkwMzAsImp0aSI6IjhkOGEwZWQxLTY1YzUtNDI5Yi04YmU2LWU2MzY4MmU5OTI1MiIsImNsaWVudF9pZCI6ImM1MzI0NzgwLWRjY2UtNDllYy1iMGY1LTMzMmYzZTA4ZWM3OCIsImFjY291bnQiOiI2Mjc5YTg0Mi0wOGJiLTQwZmMtODA1Yi0wN2M1MTgyNDE0YjciLCJ0ZW5hbnQiOiJyaW8tYnJhemlsLnByb2QifQ.zFLowr9KyU0ZhKp7YSM4YH5vKND0qY8H6wHPfwrhVHAsXS1T-upKDQnTBqpK3BI70JYfYY_kTlLTwcDzmuGNxXSMsklpVyEKw9p_wFoJYGpdDy8bq0yOreVOzalA6Wtuuzu1z6YgheQ4syP2xf80SY_Y4Ld9idyqA3rbWY9_WIHK-UECrDeTbgOEQZF3AqQCmGdgC4WInwOT18rwssX-csWOMk-97GGQrevyHtSooIlVIuFae6Uf1Wl0nT6k0Tg6fDdXF6A5hMcm8DVcDKN8jALA2T1mK2eEIwVyCvT-3pAfjcFe6hADEvgyJhPM2i0-bmzwvhoPdiaZnjFPulPl64tE0-JhB72MV4MApNpI2c7hTjoWw_yH1tLS4qYUVinAsy5quntkCzkF1gbvVdaDoKUTcpQBE3STQMAh2rEJR2qfdPDMMPz5uYaarpvR3WB2cwjMpdXtY9yg-dj1MrcWKF6i2-UXNpXJSDlOskT4TF5LQdAUc9Zpv811dXhXlgzontbemMS-vpNro30ZXUq9JL_rU-3cRManD2oghg4EDqRBBUaRmG742Ur7jQReNsi2qGa-hIdiVK_9gP6Hkdrm7aaHTSEyUbYJwYi1TUaHUqdw8h9aMfH54IGoGYdRNJ1frDu_Tpi5xXo62nZyy1QhSQmk7bLDvH5c26iMIEl76aQ",
			profile: {
				iss: "Issuer Identifier",
				aud: "Audience(s): client_id",
				exp: 129301293012930129031209301231290,
				iat: 5,
				account: "local", // evento e-delivery
				azp: "test-client",
				email: "test@example.com",
				family_name: "Client",
				given_name: "Test",
				name: "Test Client",
				sub: "prod-rio-users:mock-user",
				locale: config.login.mockLocale,
				tenant: config.login.mockTenant,
			},
			id_token: "id_token",
			session_state: "session_state",
			refresh_token: "refresh_token",
			token_type: "token_type",
			scope: "scope",
			expires_at: 101293012,
			state: "state",
		};

		const user = new User(userSettings);
		oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
		return Promise.resolve(user);
	};

	const clearStaleState = () => {
		console.info("[configuration/login/oidc-session] Stale state cleared");
		return Promise.resolve();
	};

	return { signinSilent, clearStaleState } as UserManager;
};
