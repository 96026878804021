import Spinner from "@rio-cloud/rio-uikit/Spinner";
import NoDataState from "../../../../components/common/state/NoDataState";
import { ChartJSON } from "../../types/chart";

interface SummaryAverageGearProps {
	chart: ChartJSON;
	isLoading: boolean;
	selectedDate?: string;
	selectedLevel?: string;
}

const SummaryAverageGear = ({ chart, isLoading, selectedDate, selectedLevel }: SummaryAverageGearProps) => {
	if (chart?.chart?.length === 0) {
		return (
			<div className="panel padding-20 height-400">
				<NoDataState />
			</div>
		);
	}

	if (isLoading || !chart) {
		return (
			<div className="panel padding-20 height-400">
				<Spinner />
			</div>
		);
	}

	const parseTimeToSeconds = (timeStr: string): number => {
		if (!timeStr || timeStr === "N/A") return 0;
		const [minutes, seconds] = timeStr.split(":").map(Number);
		return minutes * 60 + seconds;
	};

	const filteredChartData = selectedDate ? chart?.chart?.filter((item) => String(item.axisX) === selectedDate) : chart?.chart;

	const gearUsage: { [key: string]: number } = {};
	let totalUsage = 0;

	filteredChartData.forEach((dateData) => {
		dateData.axisY.forEach((gear) => {
			if (gear.name !== "Total") {
				const usageSeconds = parseTimeToSeconds(gear.value);
				if (typeof dateData.axisX === "string" || dateData.axisX instanceof String) {
					const axisX = String(dateData.axisX);

					if (!gearUsage[axisX]) {
						gearUsage[axisX] = 0;
					}
					gearUsage[axisX] += usageSeconds;
					totalUsage += usageSeconds;
				}
			}
		});
	});

	const sortedGearUsage = Object.entries(gearUsage)
		.map(([name, seconds]) => ({
			name,
			seconds,
			percentage: (seconds / totalUsage) * 100,
		}))
		.sort((a, b) => b.seconds - a.seconds);

	const mostUsedGear = sortedGearUsage[0];
	const leastUsedGear = sortedGearUsage[sortedGearUsage.length - 1];

	const formatSeconds = (seconds: number) => {
		const mins = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
	};

	const formatGearLevel = (gearStr: string) => {
		const gearMapping: { [key: string]: string } = {
			duration_lv_0: "Neutro",
			duration_lv_1: "1ª Marcha",
			duration_lv_2: "2ª Marcha",
			duration_lv_3: "3ª Marcha",
			duration_lv_4: "4ª Marcha",
			duration_lv_5: "5ª Marcha",
			duration_lv_6: "6ª Marcha",
			duration_lv_7: "7ª Marcha",
			duration_lv_8: "8ª Marcha",
			duration_lv_9: "9ª Marcha",
			duration_lv_10: "10ª Marcha",
			duration_lv_11: "11ª Marcha",
			duration_lv_12: "12ª Marcha",
			duration_lv_13: "13ª Marcha",
			duration_lv_14: "14ª Marcha",
			duration_lv_15: "15ª Marcha",
			duration_lv_16: "16ª Marcha",
		};
		return gearMapping[gearStr] || gearStr;
	};

	return (
		<section className="panel padding-20 display-flex flex-column gap-10">
			<div className="display-flex gap-10">
				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Tempo total de uso</div>
					<p className="text-size-h1 text-medium">{formatSeconds(totalUsage)}</p>
					<p className="text-size-p">
						{selectedDate
							? `no ${formatGearLevel(selectedDate)}`
							: `de ${formatGearLevel(String(chart?.chart[0]?.axisX))} até ${formatGearLevel(String(chart?.chart[chart?.chart?.length - 1].axisX))}`}
					</p>
				</div>

				<div className="callout width-100pct callout-success">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Marcha mais usada</div>
					<p className="text-size-h1 text-medium">{formatGearLevel(mostUsedGear?.name) || "N/A"}</p>
					<p className="text-size-p">
						{formatSeconds(mostUsedGear?.seconds || 0)} ({mostUsedGear?.percentage.toFixed(2)}%)
					</p>
				</div>

				<div className="callout width-100pct callout-warning">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Marcha menos usada</div>
					<p className="text-size-h1 text-medium">{formatGearLevel(leastUsedGear?.name) || "N/A"}</p>
					<p className="text-size-p">
						{formatSeconds(leastUsedGear?.seconds || 0)} ({leastUsedGear?.percentage.toFixed(2)}%)
					</p>
				</div>

				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Marchas monitoradas</div>
					<p className="text-size-h1 text-medium">{sortedGearUsage.length}</p>
					<p className="text-size-p">marchas registradas</p>
				</div>
			</div>
			<div className="margin-top-20">
				<table className="table table-hover width-100pct">
					<thead>
						<tr>
							<th>Marcha</th>
							<th>Tempo total</th>
							<th>Percentual</th>
							<th>Marcha mais usada</th>
							<th>Marcha menos usada</th>
						</tr>
					</thead>
					<tbody>
						{filteredChartData.map((dateData) => {
							const totalEntry = dateData.axisY.find((item) => item.name === "Total");
							const totalLevelSeconds = totalEntry ? parseTimeToSeconds(totalEntry.value) : 0;

							const gearReadings = dateData.axisY
								.filter((v) => v.name !== "Total")
								.map((v) => ({
									name: v.name,
									seconds: parseTimeToSeconds(v.value),
								}));

							const highestSeconds = Math.max(...gearReadings.map((v) => v.seconds));
							const lowestSeconds = Math.min(...gearReadings.map((v) => v.seconds));
							const bestGear = gearReadings.find((v) => v.seconds === highestSeconds);
							const worstGear = gearReadings.find((v) => v.seconds === lowestSeconds);

							return (
								<tr key={String(dateData.axisX)}>
									<td>{formatGearLevel(String(dateData.axisX))}</td>
									<td>{formatSeconds(totalLevelSeconds)}</td>
									<td>{((totalLevelSeconds / totalUsage) * 100).toFixed(2)}%</td>
									<td>
										{formatGearLevel(String(bestGear?.name))} ({formatSeconds(bestGear?.seconds || 0)})
									</td>
									<td>
										{formatGearLevel(String(worstGear?.name))} ({formatSeconds(worstGear?.seconds || 0)})
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default SummaryAverageGear;
