import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../configuration/setup/store";
import { customReportApi } from "../../services/customReportApi";
import { ChartJSON } from "../../pages/custom-report/types/chart";

interface CustomReportPageState {
	chartsFetched: ChartJSON[];
	activeChart: ChartJSON | null;
	stateActiveChart: {
		timeRangeCR: string;
		labelHidden: boolean;
		viewByDate: boolean;
		viewByPercentage: boolean;
		typeOfChart: string;
		openFullscreen: boolean;
	};
}

const initialState: CustomReportPageState = {
	chartsFetched: [],
	activeChart: {} as ChartJSON,
	stateActiveChart: {
		timeRangeCR: "DAILY",
		labelHidden: false,
		viewByDate: false,
		viewByPercentage: false,
		typeOfChart: "",
		openFullscreen: false,
	},
};

const customReportPageSlice = createSlice({
	name: "customReportPage",
	initialState,
	reducers: {
		setTypeOfChart: (state, action: PayloadAction<string>) => {
			state.stateActiveChart.typeOfChart = action.payload;
		},
		setActiveChart: (state, action: PayloadAction<ChartJSON | null>) => {
			state.activeChart = action.payload;
		},
		setTimeRangeCR: (state, action: PayloadAction<string>) => {
			state.stateActiveChart.timeRangeCR = action.payload;
		},
		setViewByDate: (state, action: PayloadAction<boolean>) => {
			state.stateActiveChart.viewByDate = action.payload;
		},
		setViewByPercentage: (state, action: PayloadAction<boolean>) => {
			state.stateActiveChart.viewByPercentage = action.payload;
		},
		setLabelHidden: (state, action: PayloadAction<boolean>) => {
			state.stateActiveChart.labelHidden = action.payload;
		},
		setOpenFullscreen: (state, action: PayloadAction<boolean>) => {
			state.stateActiveChart.openFullscreen = action.payload;
		},
		deleteChart: (state, action: PayloadAction<{ id: string }>) => {
			state.chartsFetched = state.chartsFetched.filter((chart) => chart.id !== action.payload.id);
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(customReportApi.endpoints.getListChartsIds.matchFulfilled, (state, action: PayloadAction<ChartJSON[]>) => {
			state.chartsFetched = [...action.payload].sort((a, b) => a.viewOrder - b.viewOrder);
		});
		builder.addMatcher(customReportApi.endpoints.getSingleChartById.matchFulfilled, (state, action: PayloadAction<ChartJSON>) => {
			if (action.payload && action.payload.chartType && state.stateActiveChart.typeOfChart === "") {
				state.stateActiveChart.typeOfChart = action.payload.chartType;
			}
			if (action.payload.mainData == "HISTOGRAM") {
				state.stateActiveChart.typeOfChart = "BAR";
			}
		});
		builder.addMatcher(customReportApi.endpoints.createChart.matchFulfilled, (state, action: PayloadAction<ChartJSON>) => {
			if (action.payload && action.payload.id) {
				const updatedChart = {
					...action.payload,
					startDate: action.payload.startAt || "",
					endDate: action.payload.endAt || "",
				};

				state.chartsFetched = [updatedChart, ...state.chartsFetched];
				state.activeChart = updatedChart;
			}
		});
		builder.addMatcher(customReportApi.endpoints.deleteSingleChart.matchFulfilled, (state, action: any) => {
			state.chartsFetched = state.chartsFetched.filter((chart) => chart.id !== action.meta.arg.originalArgs.id);
		});
		builder.addMatcher(customReportApi.endpoints.updateSingleChart.matchFulfilled, (state, action: any) => {
			state.activeChart = action.payload;
		});
	},
});

export const {
	setActiveChart,
	setTypeOfChart,
	setTimeRangeCR,
	setViewByPercentage,
	setViewByDate,
	setLabelHidden,
	setOpenFullscreen,
	deleteChart,
} = customReportPageSlice.actions;

export const getChartsFetched = (state: RootState) => state.pageCustomReport.chartsFetched;
export const getActiveChart = (state: RootState) => state.pageCustomReport.activeChart;
export const getCustomReportPageData = (state: RootState) => {
	return {
		...state.pageCustomReport.stateActiveChart,
		activeChart: state.pageCustomReport.activeChart,
		chartsFetched: state.pageCustomReport.chartsFetched,
		viewByPercentage: state.pageCustomReport.stateActiveChart.viewByPercentage,
	};
};

export default customReportPageSlice.reducer;
