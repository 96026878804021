import Spinner from "@rio-cloud/rio-uikit/Spinner";
import NoDataState from "../../../../components/common/state/NoDataState";
import { ChartJSON } from "../../types/chart";
import { useIntl } from "react-intl";

interface SummarySpeedCRProps {
	chart?: ChartJSON;
	isLoading: boolean;
}

const SummarySpeedCR = ({ chart, isLoading }: SummarySpeedCRProps) => {
	const intl = useIntl();

	if (chart?.chart?.length === 0 || !chart) {
		return (
			<div className="panel padding-20 height-400">
				<NoDataState />
			</div>
		);
	}

	if (isLoading) {
		return (
			<div className="panel padding-20 height-400">
				<Spinner />
			</div>
		);
	}

	const parseTimeToSeconds = (timeStr: string): number => {
		if (!timeStr || timeStr === "N/A" || timeStr === "00:00") return 0;

		const [minutes, seconds] = timeStr.split(":").map(Number);
		return minutes * 60 + seconds;
	};

	const formatSpeedLevel = (speedStr: string) => {
		const speedMapping: { [key: string]: string } = {
			duration_lv_0: "customReport.chart.SPEED.duration_lv_0",
			duration_lv_1: "customReport.chart.SPEED.duration_lv_1",
			duration_lv_2: "customReport.chart.SPEED.duration_lv_2",
			duration_lv_3: "customReport.chart.SPEED.duration_lv_3",
			duration_lv_4: "customReport.chart.SPEED.duration_lv_4",
			duration_lv_5: "customReport.chart.SPEED.duration_lv_5",
			duration_lv_6: "customReport.chart.SPEED.duration_lv_6",
			duration_lv_7: "customReport.chart.SPEED.duration_lv_7",
			duration_lv_8: "customReport.chart.SPEED.duration_lv_8",
			duration_lv_9: "customReport.chart.SPEED.duration_lv_9",
			duration_lv_10: "customReport.chart.SPEED.duration_lv_10",
			duration_lv_11: "customReport.chart.SPEED.duration_lv_11",
			duration_lv_12: "customReport.chart.SPEED.duration_lv_12",
			duration_lv_13: "customReport.chart.SPEED.duration_lv_13",
			duration_lv_14: "customReport.chart.SPEED.duration_lv_14",
			duration_lv_15: "customReport.chart.SPEED.duration_lv_15",
			duration_lv_16: "customReport.chart.SPEED.duration_lv_16",
		};
		return speedMapping[speedStr] || speedStr;
	};

	const processedData = chart.chart.map((entry) => {
		const vehicleData = entry.axisY.filter((item) => item.name !== "Total" && parseTimeToSeconds(item.value) > 0);

		const totalTime = parseTimeToSeconds(entry.axisY.find((item) => item.name === "Total")?.value || "00:00");

		return {
			speedRange: intl.formatMessage({ id: formatSpeedLevel(String(entry.axisX)) }),
			speedRangeKey: entry.axisX,
			totalTime,
			vehicles: vehicleData.map((vehicle) => ({
				name: vehicle.name,
				time: parseTimeToSeconds(vehicle.value),
			})),
		};
	});

	const grandTotalTime = processedData.reduce((sum, entry) => sum + entry.totalTime, 0);

	const averageTimePerRange = grandTotalTime / processedData.length;

	return (
		<section className="panel padding-20 display-flex flex-column gap-10">
			<div className="display-flex gap-10">
				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Tempo médio por faixa</div>
					<p className="text-size-h1 text-medium">{(averageTimePerRange / 60).toFixed(2)} min</p>
					<p className="text-size-p">tempo médio em cada faixa de velocidade</p>
				</div>
				<div className="callout width-100pct callout-success">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">
						Faixa de velocidade mais frequente
					</div>
					<p className="text-size-h1 text-medium">{processedData.length > 0 ? processedData[0].speedRange : "N/A"}</p>
					<p className="text-size-p">
						{processedData.length > 0
							? `${(processedData[0].totalTime / 60).toFixed(2)} min (${((processedData[0].totalTime / grandTotalTime) * 100).toFixed(2)}%)`
							: "Sem dados"}
					</p>
				</div>
				<div className="callout width-100pct callout-warning">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">
						Faixa de velocidade menos frequente
					</div>
					<p className="text-size-h1 text-medium">
						{processedData.length > 0 ? processedData[processedData.length - 1].speedRange : "N/A"}
					</p>
					<p className="text-size-p">
						{processedData.length > 0
							? `${(processedData[processedData.length - 1].totalTime / 60).toFixed(2)} min (${((processedData[processedData.length - 1].totalTime / grandTotalTime) * 100).toFixed(2)}%)`
							: "Sem dados"}
					</p>
				</div>
				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Faixas de velocidade</div>
					<p className="text-size-h1 text-medium">{processedData.length}</p>
					<p className="text-size-p">faixas de velocidade registradas</p>
				</div>
			</div>
			<div className="margin-top-20">
				<table className="table table-hover width-100pct">
					<thead>
						<tr>
							<th>Faixa de Velocidade</th>
							<th>Tempo Total</th>
							<th>Percentual</th>
						</tr>
					</thead>
					<tbody>
						{processedData.map((entry) => (
							<tr key={String(entry.speedRangeKey)}>
								<td>{entry.speedRange}</td>
								<td>{entry.totalTime} min</td>
								<td>{((entry.totalTime / grandTotalTime) * 100).toFixed(2)}%</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default SummarySpeedCR;
