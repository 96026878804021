import Spinner from "@rio-cloud/rio-uikit/Spinner";
import NoDataState from "../../../../components/common/state/NoDataState";
import { ChartJSON } from "../../types/chart";

interface SummaryInfractionsCRProps {
	chart: ChartJSON;
	isLoading: boolean;
	selectedDate?: string; // Optional date filter from toggle
	selectedLevel?: string; // Optional level filter for histogram
}

const SummaryInfractionsCR = ({ chart, isLoading, selectedDate, selectedLevel }: SummaryInfractionsCRProps) => {
	if (chart?.chart?.length === 0) {
		return (
			<div className="panel padding-20 height-400">
				<NoDataState />
			</div>
		);
	}

	if (isLoading || !chart) {
		return (
			<div className="panel padding-20 height-400">
				<Spinner />
			</div>
		);
	}

	const filteredChartData = selectedDate ? chart?.chart?.filter((item) => String(item.axisX) === selectedDate) : chart?.chart;

	const infractionsByDate = filteredChartData.map((dateData) => {
		const totalForDate = dateData.axisY.reduce((sum, vehicle) => sum + parseInt(vehicle.value), 0);
		return { date: String(dateData.axisX), total: totalForDate };
	});

	// Calculate total infractions across all dates
	const totalInfractions = infractionsByDate.reduce((sum, date) => sum + date.total, 0);

	// Calculate average infractions per date
	const avgInfractionsByDate = totalInfractions / infractionsByDate.length;

	// Find vehicle with most infractions
	const allVehicles: any = [];
	filteredChartData.forEach((dateData) => {
		dateData.axisY.forEach((vehicle) => {
			const existing = allVehicles.find((v: any) => v.name === vehicle.name);
			if (existing) {
				existing.total += parseInt(vehicle.value);
			} else {
				allVehicles.push({
					name: vehicle.name,
					total: parseInt(vehicle.value),
				});
			}
		});
	});

	const worstOffender = allVehicles.sort((a: { total: number }, b: { total: number }) => b.total - a.total)[0];

	// Get unique vehicle count
	const uniqueVehicleCount = allVehicles.length;

	// Calculate daily change if multiple dates exist
	let changePercentage = null;
	if (chart.chart.length > 1) {
		const day1 = chart.chart[0].axisY.reduce((sum, vehicle) => sum + parseInt(vehicle.value), 0);
		const day2 = chart.chart[1].axisY.reduce((sum, vehicle) => sum + parseInt(vehicle.value), 0);

		if (day1 > 0) {
			changePercentage = ((day2 - day1) / day1) * 100;
		}
	}

	// Format dates for display
	const formatDate = (dateStr: any) => {
		if (!dateStr) return "N/A";
		const [day, month, year] = dateStr?.split("-");
		return `${day}/${month}/${year}`;
	};

	return (
		<section className="panel padding-20 display-flex flex-column gap-10">
			<div className="display-flex gap-10">
				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Total de infrações</div>
					<p className="text-size-h1 text-medium">{totalInfractions}</p>
					<p className="text-size-p">
						{selectedDate
							? `no ${formatDate(selectedDate)}`
							: `desde ${formatDate(String(chart?.chart[0]?.axisX))} até ${formatDate(String(chart?.chart[chart?.chart?.length - 1].axisX))}`}
					</p>
				</div>

				<div className="callout width-100pct callout-warning">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Média diária infrações</div>
					<p className="text-size-h1 text-medium">{avgInfractionsByDate.toFixed(0)}</p>
					<p className="text-size-p">por {infractionsByDate.length} dia(s)</p>
				</div>

				<div className="callout width-100pct callout-danger">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Pior ofensor</div>
					<p className="text-size-h1 text-medium">{worstOffender?.name.split(" ")[0]}</p>
					<p className="text-size-p">{worstOffender?.total} infrações</p>
				</div>

				<div className="callout width-100pct callout-primary">
					<div className="text-color-primary text-medium text-size-20 line-height-125rel margin-bottom-10">Veículos monitorados</div>
					<p className="text-size-h1 text-medium">{uniqueVehicleCount}</p>
					<p className="text-size-p">infrações registradas</p>
				</div>
			</div>
			<div className="margin-top-10">
				<h3 className="text-color-primary text-medium text-size-18 margin-bottom-10">Diário</h3>
				<table className="table table-hover width-100pct">
					<thead>
						<tr>
							<th>Data</th>
							<th>Total de infrações</th>
							<th>Veículos com infrações</th>
							<th>Maior contagem de infrações</th>
						</tr>
					</thead>
					<tbody>
						{filteredChartData.map((dateData) => {
							const vehicleCount = dateData.axisY.length;
							const highestValue = Math.max(...dateData.axisY.map((v) => parseInt(v.value)));
							const highestVehicle = dateData.axisY.find((v) => parseInt(v.value) === highestValue);
							return (
								<tr key={String(dateData.axisX)}>
									<td>{formatDate(String(dateData.axisX))}</td>
									<td>{dateData.axisY.reduce((sum, v) => sum + parseInt(v.value), 0)}</td>
									<td>{vehicleCount}</td>
									<td>
										{highestVehicle?.name.split(" ")[0]} ({highestValue})
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default SummaryInfractionsCR;
