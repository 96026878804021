import { Button, ButtonDropdown, Dialog, SimpleTooltip, Spinner } from "@rio-cloud/rio-uikit";
import html2canvas from "html2canvas";
import { useState } from "react";
import * as XLSX from "xlsx";
import DateSelector from "../../../components/common/date/DateSelector";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { getCustomReportPageData, setOpenFullscreen } from "../../../stores/customReport/customReportPageSlice";
import BarChartCR from "../charts/bar/BarChartCR";
import HistogramChartCR from "../charts/HistogramChartCR";
import LineChartCR from "../charts/LineChartCR";
import PieChartCR from "../charts/PieChartCR";
import ChartSelector from "../selectors/ChartSelector";
import { ChartJSON } from "../types/chart";

interface ActiveChartProps {
	singleChart: ChartJSON;
	isLoadingSingleChart: boolean;
}

const ActiveChart = ({ singleChart, isLoadingSingleChart }: ActiveChartProps) => {
	const { typeOfChart, openFullscreen, activeChart, labelHidden } = useAppSelector(getCustomReportPageData);
	const [openDownloadOptions, setOpenDownloadOptions] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const chartRendered = (!isLoadingSingleChart && singleChart?.chart?.length > 0) || singleChart?.chart[0]?.axisY?.length > 0;
	const dispatch = useAppDispatch();

	const handleXLSXDownload = () => {
		if (!singleChart?.chart?.[0]?.axisY?.length) {
			console.error("No chart data available to download");
			return;
		}

		const wb = XLSX.utils.book_new();

		const chartData = singleChart.chart[0].axisY.map((item) => ({
			Veículo: item.name,
			Valor: Number(item.value),
		}));

		const worksheet = XLSX.utils.json_to_sheet(chartData);
		XLSX.utils.book_append_sheet(wb, worksheet, singleChart.chartType);
		XLSX.writeFile(wb, `${singleChart.chartName + " " + singleChart.chartType || "chart"}.xlsx`);
	};

	const handleDownload = () => {
		const chartElement = document.getElementById("chart");
		if (chartElement) {
			setIsDownloading(true);
			html2canvas(chartElement, {
				backgroundColor: "white",
				scale: 2,
			})
				.then((canvas) => {
					canvas.toBlob((blob) => {
						if (!blob) {
							console.error("Blob creation failed");
							setIsDownloading(false);
							return;
						}
						const link = document.createElement("a");
						link.href = URL.createObjectURL(blob);
						link.download = `${singleChart?.chartName || "chart"}.png`;
						link.style.display = "none";
						document.body.appendChild(link);
						link.click();
						setTimeout(() => {
							document.body.removeChild(link);
							URL.revokeObjectURL(link.href);
							setIsDownloading(false);
						}, 100);
					}, "image/png");
				})
				.catch((err) => {
					console.error("Canvas creation error:", err);
					setIsDownloading(false);
				});
		} else {
			console.error("Chart element not found with ID 'chart'");
			setIsDownloading(false);
		}
	};

	const downloadOptions = [
		{
			value: (
				<span className="display-flex gap-5 align-items-center">
					<span className="rioglyph rioglyph-pictures" />
					<span>Imagem</span>
				</span>
			),
			onSelect: () => handleDownload(),
		},
		{
			value: (
				<span className="display-flex gap-5 align-items-center">
					<span className="rioglyph rioglyph-table-view" />
					<span>XLSX</span>
				</span>
			),
			onSelect: () => handleXLSXDownload(),
		},
	];

	const renderCorrectChart = () => {
		if (!activeChart) return;
		switch (typeOfChart) {
			case "BAR":
				if (activeChart.mainData == "HISTOGRAM") {
					return <HistogramChartCR histogramChartData={singleChart} />;
				} else {
					return <BarChartCR barChartData={singleChart} />;
				}
			case "LINE":
				return <LineChartCR lineChartData={singleChart} />;
			case "PIE":
				return <PieChartCR pieChartData={singleChart} />;
			default:
				return null;
		}
	};

	return (
		<div className="width-100pct width-75pct-lg panel display-flex gap-10 flex-column height-100pct align-items-center padding-20 width-100pct">
			{openFullscreen && (
				<Dialog
					showCloseButton={true}
					onClose={() => dispatch(setOpenFullscreen(false))}
					show={openFullscreen}
					bsSize={"fullscreen"}
					title={singleChart?.chartName}
					body={
						<div className="height-90pct width-100pct">
							{!singleChart ? (
								<Spinner />
							) : (
								<>
									<div className="max-width-350">
										<DateSelector />
									</div>
									{renderCorrectChart()}
								</>
							)}
						</div>
					}
				/>
			)}
			{chartRendered && (
				<div className="display-flex height-10pct justify-content-between width-100pct align-items-center">
					<div className="display-flex padding-5 width-100pct align-items-center">
						<ChartSelector />
						<ButtonDropdown
							className={"position-relative"}
							open={openDownloadOptions}
							bsStyle={Button.MUTED}
							title={
								<span className={`rioglyph rioglyph rioglyph-${isDownloading ? "spinner spinning" : "download"}`} aria-hidden={"true"} />
							}
							items={downloadOptions}
							onOpen={() => setOpenDownloadOptions(true)}
							onClose={() => setOpenDownloadOptions(false)}
							iconOnly
						/>
						<Button bsStyle={Button.MUTED} iconOnly iconName="rioglyph-fullscreen" onClick={() => dispatch(setOpenFullscreen(true))} />
					</div>
					{labelHidden && (
						<SimpleTooltip content="Campos do eixo X ocultos para melhor renderização">
							<span className="rioglyph rioglyph-info btn btn-link" />
						</SimpleTooltip>
					)}
				</div>
			)}
			<div className="height-90pct width-100pct" id="chart">
				{isLoadingSingleChart ? <Spinner /> : <>{renderCorrectChart()}</>}
			</div>
		</div>
	);
};

export default ActiveChart;
