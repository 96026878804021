import { Dialog, ListMenu, SimpleTooltip } from "@rio-cloud/rio-uikit";
import Button from "@rio-cloud/rio-uikit/Button";
import ConfirmationDialog from "@rio-cloud/rio-uikit/components/dialog/ConfirmationDialog";
import { MouseEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { getCustomReportPageData, setActiveChart } from "../../../stores/customReport/customReportPageSlice";
import { useIntl } from "react-intl";

interface ChartActionsProps {
	setOpenCreateChartDialog: (val: boolean) => void;
	setConfirmChartDelete: (val: boolean) => void;
	handleDeleteChart: (id: string) => void;
	showConfirmChartDelete: boolean;
}

const ChartActions = ({
	showConfirmChartDelete,
	handleDeleteChart,
	setOpenCreateChartDialog,
	setConfirmChartDelete,
}: ChartActionsProps) => {
	const [openListCharts, setOpenListCharts] = useState(false);
	const { activeChart, chartsFetched } = useAppSelector(getCustomReportPageData);
	const dispatch = useAppDispatch();
	const intl = useIntl();
	if (!activeChart) return null;

	const onItemClick = (event: MouseEvent<HTMLSpanElement>) => {
		const key = event.currentTarget.getAttribute("data-key");
		if (key) {
			const activeChartFiltered = chartsFetched.find((chart) => chart.id == key);
			if (activeChartFiltered) {
				dispatch(setActiveChart(activeChartFiltered));
				setOpenListCharts(false);
			}
		}
	};

	const menuItems = [
		{
			navItems: chartsFetched.map((item) => ({
				key: item.chartName + item.id,
				disabled: false,
				item: (
					<span
						className={activeChart.id === item.id ? "active" : ""}
						style={{ marginLeft: "-0px" }}
						data-key={item.id}
						onClick={onItemClick}
					>
						{item.chartName}
					</span>
				),
			})),
		},
	];

	return (
		<div className="width-100pct display-flex justify-content-between height-35">
			{openListCharts && (
				<Dialog
					useOverflow={true}
					show={openListCharts}
					title="Lista de relatórios customizados"
					bsSize={"sm"}
					onClose={() => setOpenListCharts(false)}
					body={
						<>
							<ListMenu
								menuItems={menuItems.map((item) => ({
									...item,
									badge: `${item.navItems.length} items`,
									badgeType: "muted",
								}))}
								filterPlaceholder="Filtrar por nome do relatório"
								notFoundMessage="Nada encontrado"
								enableFilter
								focusFilter
							/>
						</>
					}
				/>
			)}
			{showConfirmChartDelete && (
				<ConfirmationDialog
					useOverflow={true}
					show={showConfirmChartDelete}
					title="Deletar relatório customizado?"
					content={
						<>
							<div className="display-flex gap-2 flex-column">
								<span>
									<b>Nome do relatório: </b>
									{activeChart.chartName}
								</span>
								<span>
									<b>Conteúdo principal:</b> {intl.formatMessage({ id: `customReport.chart.${activeChart.mainData}` })}
								</span>
							</div>
						</>
					}
					bsSize={"sm"}
					onClickConfirm={() => {
						setConfirmChartDelete(false);
						handleDeleteChart(activeChart.id);
					}}
					onClickCancel={() => setConfirmChartDelete(false)}
					cancelButtonText="Cancelar"
					confirmButtonText={"Deletar"}
				/>
			)}
			<div className="display-flex gap-5">
				{chartsFetched?.length > 0 && (
					<>
						<SimpleTooltip content="Procurar um relatório">
							<Button iconOnly iconName="rioglyph-search" onClick={() => setOpenListCharts(true)} />
						</SimpleTooltip>
					</>
				)}

				<Button onClick={() => setOpenCreateChartDialog(true)} iconName="rioglyph-plus">
					Criar um relatório
				</Button>
			</div>
		</div>
	);
};

export default ChartActions;
